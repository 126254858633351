import AiIcon from '@shared/assets/images/ai-avatar.svg';
import ChatBus from './ChatBus';
import {
  generateLoanTranslationSchema,
  generateBorrowerTranslationSchema,
  generatePropertyTranslationSchema,
  generateSearchTranslationSchema,
  extractOptions,
  filterSchema,
} from './TranslationUtils.js';

import * as NLIService from '@shared/services/nli.js';
import * as SemanticMatchService from '@shared/components/Chat/services/SemanticMatchService.js';

import {
  generateResponse,
  loanTranslationSentiments,
  pricingRetrievalSentiments,
  genericTaskCompleteSentiments,
  followUps,
  apologies,
  pleasantryList,
  formUpdateFailureList,
  tryAgainList,
  fieldIssuesList,
  affirmatives,
  loadedScenarioFailureSentiments,
  ineligibleProductFailureSentiments,
  loadedScenarioSentiments,
  savedScenarioFailureSentiments,
  savedScenarioSentiments,
  celebrations,
} from './responseChunks.js';
import { mapGetters, mapActions } from 'vuex';
import { saveScenario } from '@pe/components/Pricing/pricingUtils';

import axios from 'axios';
import clone from 'lodash/cloneDeep';
import { getPricingScenarios } from '@pe/services/pricingScenarios';
import MessageReaction from './MessageReaction.vue';
import ChatHeader from './ChatHeader.vue';
import ChatWidgetToggle from './ChatWidgetToggle.vue';
import ChatSettings from './ChatSettings.vue';
import ChatMessageList from './ChatMessageList.vue';
import ChatInput from './ChatInput.vue';
import { filterObject } from '@src/shared/utils/helpers';
import { onErrorHandler } from '@src/shared/utils/errorHandlers';
import { camelCaseToTitleCase } from '@src/shared/utils/stringFormatters';

const loanScenarioPaths = [
  '/pe/loan-scenario',
  '^/pe/loans/.*$',
  '^/pe/loans-embed.*$',
];
const assistantTaskInfo = {
  'Update A Loan Scenario': {
    requirements: [
      'Loan Scenario Information.',
      'Must be on loan scenario page.',
    ],
    howToUse: ["Tell me about the loan scenario, I'll fill it out for you."],
  },
  'Save/Load A Loan Scenario': {
    requirements: ['Name of loan scenario.'],
    howToUse: ['Ask me to save or load a scenario and give me a name.'],
  },
  'Get Pricing': {
    requirements: [],
    howToUse: ["Simply ask me to get pricing, I'll take care of the rest."],
  },
  'Recommend Pricing Options': {
    requirements: [],
    howToUse: [
      "Ask me for pricing recommendations based on your needs, and I'll point out notable rates.",
    ],
  },
  'Explain Product Ineligibility': {
    requirements: ['Name of Product'],
    howToUse: [
      "Ask me about an ineligible product, I'll explain why it's not eligible.",
    ],
  },
  'Answer Mortgage Questions': {
    requirements: [],
    howToUse: ["Ask me a mortgage question, I'll do my best to answer it."],
  },
};

export default {
  components: {
    MessageReaction,
    ChatHeader,
    ChatWidgetToggle,
    ChatSettings,
    ChatMessageList,
    ChatInput,
  },
  props: {
    messages: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isSending: false,
      isExpanded: false,
      headerText: 'Polly Assistant',
      allMessages: this.messages,
      otherProfileImage: AiIcon,
      toggleIcon: AiIcon,
      botName: 'POLL-E',
      vocalResponseEnabled: false,
      autoPricingEnabled: false,
      currentlyPlayingAudio: null,
      showSettings: false,
      pricingRequested: false,
      bestPrices: null,
      eligibleProducts: null,
      ineligibleProducts: null,
      customParameters: {},
      template: {},
      optionMaps: {},
      currentPricingParameters: {},
      scenarioRetrievalOptions: {
        page_size: 100,
      },
      currentHistory: [
        {
          role: 'assistant',
          content: this.messages[0].text,
          sentAt: new Date(),
        },
      ],
      historyLength: 12,
      jsonHistoryLength: 2,
      trafficCopHistoryLength: 2,
      ineligibilityHistoryLength: 0,
      devMode: process.env.NODE_ENV !== 'production',
      currentMessage: null,
    };
  },
  computed: {
    ...mapGetters({
      pricingTemplate: 'pricing/pricingTemplate',
      organization: 'organizations/organization',
    }),
  },
  mounted() {
    this.generateSchemas();

    // Listen for the event signifying templates have been updated on the loan scenario page and update schemas accordingly
    ChatBus.$on('loan-template-updated', template => {
      this.template = template;
      this.generateSchemas(
        template,
        this.customParameters,
        this.currentPricingParameters,
      );
    });

    ChatBus.$on('pricing-parameters-updated', currentPricingParameters => {
      this.currentPricingParameters = currentPricingParameters;
      this.generateSchemas(
        this.template,
        this.customParameters,
        currentPricingParameters,
      );
    });

    ChatBus.$on('pricing-error', errors => {
      const bulletList = this.parseFieldErrors(errors);

      this.addMessage({
        sender: this.botName,
        text: generateResponse(
          fieldIssuesList,
          ['\n'.concat(bulletList)],
          apologies,
          null,
          0.5,
          ':',
          '',
        ),
      });
      this.pricingRequested = false;
    });

    ChatBus.$on('pricing-success', pricingData => {
      this.bestPrices = pricingData.minPrices;
      this.eligibleProducts = pricingData.eligibleProducts;
      this.ineligibleProducts = pricingData.ineligibleProducts;
      this.pricingScenario = pricingData.pricingScenario;

      if (this.pricingRequested) {
        this.explainPricing(true);
      }
      this.pricingRequested = false;
    });

    ChatBus.$on('custom-parameters-updated', customParameters => {
      this.customParameters = customParameters || {};
      this.generateSchemas(this.template, customParameters);
    });
  },
  beforeDestroy() {
    // Make sure to remove event listener to prevent memory leaks
    ChatBus.$off('loan-template-updated');
    ChatBus.$off('pricing-error');
    ChatBus.$off('pricing-success');
    ChatBus.$off('custom-parameters-updated');
  },
  methods: {
    setVocalResponseEnabled(value) {
      this.vocalResponseEnabled = value;
    },
    setAutoPricingEnabled(value) {
      this.autoPricingEnabled = value;
    },
    handleReactionSelection(id, reaction) {
      const message = this.allMessages.find(msg => msg.id === id);
      message.reaction = reaction;
    },
    toggleExpanded() {
      this.isExpanded = !this.isExpanded;
    },
    handleToggleSettings() {
      this.showSettings = !this.showSettings;
    },
    generateSchemas(
      template = {},
      customParameters = {},
      currentPricingParameters = {},
    ) {
      // Call your functions to generate the schemas here
      const loanCustomParameters = filterObject(
        customParameters,
        param => param?.valueCategory?.toLowerCase() === 'loan',
      );
      this.loanTranslationSchema = generateLoanTranslationSchema(
        template,
        loanCustomParameters,
        currentPricingParameters?.loan || {},
      );

      const borrowerCustomParameters = filterObject(customParameters, param =>
        ['borrower', 'custom'].includes(param?.valueCategory?.toLowerCase()),
      );
      this.borrowerTranslationSchema = generateBorrowerTranslationSchema(
        template,
        borrowerCustomParameters,
        currentPricingParameters?.borrower || {},
      );

      const propertyCustomParameters = filterObject(
        customParameters,
        param => param?.valueCategory?.toLowerCase() === 'property',
      );
      this.propertyTranslationSchema = generatePropertyTranslationSchema(
        template,
        propertyCustomParameters,
        currentPricingParameters?.property || {},
      );

      this.searchTranslationSchema = generateSearchTranslationSchema(
        template,
        currentPricingParameters?.criteria || {},
      );

      this.optionMaps = {
        ...extractOptions(this.loanTranslationSchema),
        ...extractOptions(this.borrowerTranslationSchema),
        ...extractOptions(this.propertyTranslationSchema),
        ...extractOptions(this.searchTranslationSchema),
      };
    },
    pageMatch(paths) {
      const currentPath = this.$router.currentRoute.path;
      return paths.some(path => new RegExp(path).test(currentPath));
    },
    wrongPageResponse(clarification = null, link = null, linkText = null) {
      return {
        sender: this.botName,
        text: `I'm sorry, I can't do that here. ${clarification}`,
        link: link,
        linkText: linkText,
      };
    },
    addToHistory(item) {
      this.currentHistory.push(item);

      if (this.currentHistory.length > this.historyLength) {
        this.currentHistory.shift();
      }

      return this.currentHistory;
    },
    historyToString(mostRecentNumber = this.historyLength) {
      const trimmedHistory = this.trimHistoryToMostRecentAssistant(
        this.currentHistory,
      );
      const messages =
        mostRecentNumber === 0
          ? []
          : trimmedHistory
              .slice(-mostRecentNumber)
              .map(
                message =>
                  '(' +
                  message.sentAt.toLocaleString() +
                  ') ' +
                  message.role +
                  ': ' +
                  message.content,
              );
      return messages.join('\n');
    },
    // This method trims the "current" user message from the history if it exists
    trimHistoryToMostRecentAssistant(history = this.currentHistory) {
      // Reverse the messages, so they start from the end
      const reversedHistory = [...history].reverse();
      // Find the index of the first Assistant message
      const assistantMessageIndex = reversedHistory.findIndex(
        message => message.role === 'assistant',
      );

      // If an assistant message is found, splice out the messages before it
      if (assistantMessageIndex !== -1) {
        reversedHistory.splice(0, assistantMessageIndex);
      }

      return reversedHistory.reverse();
    },
    sendMessage(newMessageText) {
      this.isSending = true;
      this.currentMessage = {
        user_message: newMessageText,
      };
      this.executeNliAction('traffic-cop', async options =>
        NLIService.trafficCop(
          {
            prompt: newMessageText,
            history: this.historyToString(this.trafficCopHistoryLength),
          },
          options,
        ),
      ).then(response => {
        this.routeMessage(response.data.result, response.data.prompt);
      });

      this.addMessage({
        user: true,
        text: newMessageText,
      });
      this.$nextTick(() => {
        // Get chat messages element
        const chatMessages = document.querySelector('.chat-widget__messages');

        // Scroll to the bottom
        chatMessages.scrollTop = chatMessages.scrollHeight;
      });
    },
    async routeMessage(action, message) {
      let response = null;
      switch (action) {
        case 'json-translation':
          response = await this.translateLoanScenario(message);
          break;
        case 'mortgage-question':
          response = await this.mortgageInfo(message, [
            'If the question does not relate to the mortgage industry, politely redirect the conversation.',
          ]);
          break;
        case 'get-pricing':
          response = this.getPricing();
          break;
        case 'explain-pricing':
          response = await this.searchRateStack(message);
          break;
        case 'load-scenario':
          response = await this.loadLoanScenario(message);
          break;
        case 'save-scenario':
          response = await this.saveLoanScenario(message);
          break;
        case 'explain-ineligibility':
          response = await this.explainIneligibility(message);
          break;
        case 'explain-near-miss':
          response = await this.explainNearMiss(message);
          break;

        default:
          response = await this.mortgageInfo(
            message,
            [
              'If the question does not relate to the mortgage industry, helpful information, or your abilities, politely redirect the conversation.',
              'Let the user know about the tasks you can assist with.',
              'If asked about a specific task, use the provided task information to answer the question. howToUse explains how the user can interact with you to perform the task.',
              "You don't need to mention mortgages unless asked.",
            ],
            'Tasks You Can Perform:\n\n' + JSON.stringify(assistantTaskInfo),
          );
          break;
      }

      if (response === null) {
        return;
      } else if (
        typeof response === 'object' &&
        'text' in response &&
        'sender' in response
      ) {
        this.addMessage(response);
      } else {
        this.addMessage({ sender: this.botName, text: response });
      }
    },
    async translateLoanScenario(message) {
      if (!this.pageMatch(loanScenarioPaths)) {
        return this.wrongPageResponse(
          "If you'd like to update your loan scenario please go to the ",
          '/pe/loan-scenario',
          'Loan Scenario Page',
        );
      }

      let isAllEmpty = true;
      const disabledFields = [];

      const promises = [
        this.executeNliAction(
          'json-translation',
          async options =>
            NLIService.translateJson(
              {
                translationSchema: JSON.stringify(
                  this.loanTranslationSchema,
                  null,
                  0,
                ),
                prompt: message,
                history: this.historyToString(this.jsonHistoryLength),
              },
              options,
            ),
          {},
          'loan-translation',
        ).then(response => {
          const { isEmpty, newDisabledFields } = this.handleTranslationResponse(
            response,
            this.template.loan,
            'loan-translation',
          );
          isAllEmpty = isAllEmpty && isEmpty;
          disabledFields.push(...newDisabledFields);
        }),
        this.executeNliAction(
          'json-translation',
          async options =>
            NLIService.translateJson(
              {
                translationSchema: JSON.stringify(
                  this.borrowerTranslationSchema,
                  null,
                  0,
                ),
                prompt: message,
                history: this.historyToString(this.jsonHistoryLength),
              },
              options,
            ),
          {},
          'borrower-translation',
        ).then(response => {
          const { isEmpty, newDisabledFields } = this.handleTranslationResponse(
            response,
            this.template.borrower,
            'borrower-translation',
          );
          isAllEmpty = isAllEmpty && isEmpty;
          disabledFields.push(...newDisabledFields);
        }),
        this.executeNliAction(
          'json-translation',
          async options =>
            NLIService.translateJson(
              {
                translationSchema: JSON.stringify(
                  this.propertyTranslationSchema,
                  null,
                  0,
                ),
                prompt: message,
                history: this.historyToString(this.jsonHistoryLength),
              },
              options,
            ),
          {},
          'property-translation',
        ).then(async response => {
          const { isEmpty, newDisabledFields } = this.handleTranslationResponse(
            response,
            this.template.property,
            'property-translation',
          );
          isAllEmpty = isAllEmpty && isEmpty;
          disabledFields.push(...newDisabledFields);
        }),
        this.executeNliAction(
          'json-translation',
          async options =>
            NLIService.translateJson(
              {
                translationSchema: JSON.stringify(
                  this.searchTranslationSchema,
                  null,
                  0,
                ),
                prompt: message,
                history: this.historyToString(this.jsonHistoryLength),
              },
              options,
            ),
          {},
          'search-translation',
        ).then(response => {
          const { isEmpty, newDisabledFields } = this.handleTranslationResponse(
            response,
            this.template.criteria,
            'search-translation',
          );
          isAllEmpty = isAllEmpty && isEmpty;
          disabledFields.push(...newDisabledFields);
        }),
      ];

      await Promise.all(promises).catch(error => {
        console.error(error);
        // handle error as you see fit
      });

      if (disabledFields.length > 0 && !isAllEmpty) {
        return generateResponse(
          loanTranslationSentiments,
          [
            "Unfortunately I couldn't update the following fields because they are disabled: \n\n- " +
              disabledFields.join('\n- '),
          ],
          null,
          null,
          0.5,
          '.',
          '',
        );
      }

      if (disabledFields.length > 0) {
        return (
          "I'm sorry, I can't update the following fields because they are disabled: \n\n- " +
          disabledFields.join('\n- ')
        );
      }

      // Return special response if all translations are empty
      if (isAllEmpty) {
        return generateResponse(
          formUpdateFailureList,
          tryAgainList,
          apologies,
          pleasantryList,
          0.8,
          '.',
          '.',
        );
      }

      if (this.autoPricingEnabled) {
        this.getPricing();
        return null;
      }

      return generateResponse([...loanTranslationSentiments], followUps, [
        ...celebrations,
        ...genericTaskCompleteSentiments,
      ]);
    },
    handleTranslationResponse(response, template, translationEvent) {
      let isEmpty = true;
      const { filteredSchema: translation, disabledRemovedKeys } = filterSchema(
        JSON.parse(response.data.result),
        template,
      );
      const newDisabledFields = [
        ...disabledRemovedKeys.map(key => camelCaseToTitleCase(key)),
      ];

      if (Object.keys(translation).length > 0) {
        ChatBus.$emit(translationEvent, translation);
        isEmpty = false;
      }
      return { isEmpty, newDisabledFields };
    },
    async searchRateStack(message) {
      if (!this.pageMatch(loanScenarioPaths)) {
        return this.wrongPageResponse(
          "If you'd like to look up rate stack information please go to the ",
          '/pe/loan-scenario',
          'Loan Scenario Page',
        );
      }

      if (!this.eligibleProducts) {
        return generateResponse(
          ["I don't see any eligible products"],
          ['Please re-run pricing and ask again'],
          apologies,
          null,
          0.9,
          '.',
          '.',
        );
      }

      const recalibratedPrompt = await this.normalizeAndTag(
        message,
        SemanticMatchService.rateStackGlossary,
      );
      const selectedColumns = await this.selectColumns(recalibratedPrompt);
      const productRates = SemanticMatchService.instantiateProductRates(
        this.eligibleProducts,
      );
      let selectedRates = await this.executeSemanticMatchTournament(
        productRates,
        recalibratedPrompt,
        selectedColumns,
      );
      selectedRates = SemanticMatchService.sortRatesByRate(selectedRates);

      const finalSelectionResults = await this.executeNliAction(
        'rate-stack-search',
        async options =>
          NLIService.semanticMatch(
            {
              prompt: recalibratedPrompt,
              options: SemanticMatchService.formatRateStackSearchResultsString(
                selectedRates,
                selectedColumns,
              ),
              match_count: 5,
            },
            options,
          ),
      );

      const finalSelectedRates = SemanticMatchService.getRatesFromRateIds(
        [finalSelectionResults.data.result],
        productRates,
      );

      const semanticMatchResponse = await this.executeNliAction(
        'rate-explanation',
        async options =>
          NLIService.explainRates(
            {
              prompt: message,
              additional_context: `${SemanticMatchService.formatRateStackSearchResultsString(
                finalSelectedRates,
              )}`,
              history: this.historyToString(this.historyLength),
            },
            options,
          ),
      );

      return semanticMatchResponse.data.result;
    },
    async executeSemanticMatchTournament(
      productRates,
      recalibratedPrompt,
      selectedColumns,
    ) {
      let selectedRates = productRates;
      while (selectedRates.length > 15) {
        SemanticMatchService.shuffleArray(selectedRates);
        const groups = SemanticMatchService.groupRates(selectedRates);
        const matchCount = SemanticMatchService.determineMatchCount(groups);

        // Make match selections in parallel
        const promises = groups.map(group => {
          group = SemanticMatchService.sortRatesByRate(group);
          return this.executeNliAction('rate-stack-search', async options =>
            NLIService.semanticMatch(
              {
                prompt: recalibratedPrompt,
                options:
                  SemanticMatchService.formatRateStackSearchResultsString(
                    group,
                    selectedColumns,
                  ),
                match_count: matchCount,
              },
              options,
            ),
          );
        });

        const selectionResults = await Promise.all(promises).catch(error => {
          onErrorHandler(error, 'semantic-match', [], true);
        });

        selectedRates = SemanticMatchService.getRatesFromRateIds(
          selectionResults?.map(result => result?.data?.result) || [],
          productRates,
        );
      }

      return selectedRates;
    },
    async selectColumns(message) {
      const columnSelectionResponse = await this.executeNliAction(
        'column-selection',
        async options =>
          NLIService.selectColumns(
            {
              prompt: message,
            },
            options,
          ),
      );

      return JSON.parse(columnSelectionResponse.data.result);
    },
    async normalizeAndTag(message, glossary) {
      const normalizationResponse = await this.executeNliAction(
        'normalize-and-tag',
        async options =>
          NLIService.normalizeAndTag(
            {
              prompt: message,
              glossary: glossary,
            },
            options,
          ),
      );

      return normalizationResponse.data.result;
    },
    async mortgageInfo(
      message,
      additionalRules = [],
      additionalContext = null,
      model = null,
      historyLength = this.historyLength,
    ) {
      const contextualizationResponse = await this.executeNliAction(
        'contextualizer',
        async options =>
          NLIService.contextualize(
            {
              prompt: message,
              history: this.historyToString(historyLength),
            },
            options,
          ),
      );

      const contextualizedPrompt = contextualizationResponse.data.result;

      const response = await this.executeNliAction(
        'mortgage-info',
        async options =>
          NLIService.mortgageInfo(
            {
              prompt: message,
              rag_prompt: contextualizedPrompt,
              additional_rules: additionalRules,
              additional_context: additionalContext,
              model: model,
              history: this.historyToString(historyLength),
            },
            options,
          ),
      );

      return response.data.result;
    },
    async loadLoanScenario(message) {
      if (this.pageMatch(loanScenarioPaths)) {
        const response = await getPricingScenarios(
          this.organization.id,
          this.scenarioRetrievalOptions,
        );
        const scenarioMap = response.results.map(result => ({
          name: result.scenario.name,
          id: result.scenario.id,
        }));

        const optionResponse = await this.executeNliAction(
          'option-selection',
          async options =>
            NLIService.optionSelection(
              {
                prompt: message,
                options: JSON.stringify(scenarioMap),
              },
              options,
            ),
        );

        if (optionResponse.status && optionResponse.data.result >= 1) {
          const selectedScenario = response.results.find(result => {
            return result.scenario.id == optionResponse.data.result;
          });

          this.mergeScenarioWithTemplate({
            scenario: selectedScenario.pe3_custom_request,
            customParameters: this.customParameters,
          });
          ChatBus.$emit('load-scenario');

          return generateResponse(
            [...loadedScenarioSentiments, ...genericTaskCompleteSentiments],
            followUps,
            celebrations,
          );
        }
        // Failed to load scenario
        return generateResponse(
          [...loadedScenarioFailureSentiments],
          tryAgainList,
          apologies,
          null,
          0.8,
          '.',
          '.',
        );
      }

      return this.wrongPageResponse(
        "If you'd like to load a loan scenario please go to the ",
        '/pe/loan-scenario',
        'Loan Scenario Page',
      );
    },
    async saveLoanScenario(message) {
      if (this.pageMatch(loanScenarioPaths)) {
        const scenarioNameResponse = await this.executeNliAction(
          'name-extraction',
          async options =>
            NLIService.nameExtraction(
              {
                prompt: message,
                additional_rules: [
                  'Extract the name of the scenario. (ex: {message: Save this scenario as investment property, name: investment property)',
                ],
              },
              options,
            ),
        );

        if (
          scenarioNameResponse.status &&
          scenarioNameResponse.data.result.toLowerCase() !== 'none'
        ) {
          await saveScenario(
            scenarioNameResponse.data.result,
            this.pricingTemplate,
            this.customParameters,
            this.organization.id,
          );

          return generateResponse(
            [...savedScenarioSentiments, ...genericTaskCompleteSentiments],
            followUps,
          );
        }
        // Failed to load scenario
        return generateResponse(
          [...savedScenarioFailureSentiments],
          tryAgainList,
          apologies,
          null,
          0.8,
          '.',
          '.',
        );
      }

      return this.wrongPageResponse(
        "If you'd like to save a loan scenario please go to the ",
        '/pe/loan-scenario',
        'Loan Scenario Page',
      );
    },
    async explainIneligibility(message) {
      if (!this.pageMatch(loanScenarioPaths)) {
        return this.wrongPageResponse(
          "If you'd like to interact with products and pricing please go to the ",
          '/pe/loan-scenario',
          'Loan Scenario Page',
        );
      }

      if (!this.ineligibleProducts) {
        return generateResponse(
          ["I don't see any ineligible products"],
          ['Please re-run pricing and ask again'],
          apologies,
          null,
          0.9,
          '.',
          '.',
        );
      }

      const optionResponse = await this.executeNliAction(
        'option-selection',
        async options =>
          NLIService.optionSelection(
            {
              prompt: message,
              options: JSON.stringify(
                this.ineligibleProducts.map(product => ({
                  name: product.name,
                  id: product.id,
                })),
              ),
            },
            options,
          ),
      );

      const trimmedOptionId = optionResponse.data.result.replace(/"/g, '');
      const selectedProduct = this.ineligibleProducts.find(product => {
        return product.id == trimmedOptionId;
      });
      if (optionResponse.status && trimmedOptionId != -1 && selectedProduct) {
        const loanScenarioResult = { ...this.pricingScenario };
        const selectedProductCode = selectedProduct['code'];
        loanScenarioResult['Search']['ProductCodes'] = [selectedProductCode];
        const loanScenarioAnalysis = await NLIService.scenarioAnalysis(
          loanScenarioResult,
        );
        const ineligibleProductAnalysis =
          loanScenarioAnalysis.data.ineligibleProducts[0];
        const criticalRuleNames = ineligibleProductAnalysis.ruleResults.map(
          ruleResult => ruleResult.ruleName,
        );

        if (criticalRuleNames.length === 0) {
          return 'Oops! It seems like I’ve hit a snag analyzing this rule. I’m really sorry for the inconvenience. Rest assured, I’ve already flagged this for further review. Our team is always working on improvements and we’ll make sure to enhance our ability to handle situations like this in the future. Thank you for your patience and understanding.';
        }

        // only take the first solution for now, handle multiple later
        const ineligibilitySolution = ineligibleProductAnalysis.solutions[0];
        const response = await this.executeNliAction(
          'explain-ineligibility',
          async options =>
            NLIService.explainLoanIneligibility(
              {
                prompt: message,
                additional_context: `${
                  selectedProduct.name
                } Failed Requirements: \n${this.parseSolutionRequirements(
                  ineligibilitySolution,
                )}`,
              },
              options,
            ),
        );

        return response.data.result;
      }

      // Failed to find ineligible product by name
      return generateResponse(
        [...ineligibleProductFailureSentiments],
        tryAgainList,
        apologies,
        null,
        0.8,
        '.',
        '.',
      );
    },
    async explainNearMiss(message) {
      if (!this.pageMatch(loanScenarioPaths)) {
        return this.wrongPageResponse(
          "If you'd like to interact with products and pricing please go to the ",
          '/pe/loan-scenario',
          'Loan Scenario Page',
        );
      }
      if (!this.ineligibleProducts) {
        return generateResponse(
          ["I don't see any ineligible products"],
          ['Please re-run pricing and ask again'],
          apologies,
          null,
          0.9,
          '.',
          '.',
        );
      }
      const pricingScenarioTemplate = clone(this.pricingScenario);
      const ineligibleProductCodes = this.ineligibleProducts.map(
        product => product.code,
      );
      pricingScenarioTemplate['Search']['ProductCodes'] =
        ineligibleProductCodes;
      const nearMissResults = await NLIService.nearMiss({
        ...pricingScenarioTemplate,
        currentOptions: this.optionMaps,
      });
      const nearMissProducts = nearMissResults
        ? nearMissResults.data.eligibleProducts
        : [];
      const nearMissContext = this.parseNearMissContext(nearMissProducts);
      if (nearMissContext.length == 0) {
        return await this.mortgageInfo(message, [
          "Explain to the user that you're unable to find any additional products that could easily be made eligible right now.",
          'Do not make any additional offers or suggestions.',
        ]);
      }
      const response = await this.executeNliAction(
        'explain-near-miss',
        async options =>
          NLIService.explainNearMiss(
            {
              prompt: message,
              additional_context: nearMissContext,
            },
            options,
          ),
      );
      return response.data.result;
    },
    getPricing() {
      if (this.pageMatch(loanScenarioPaths)) {
        this.pricingRequested = true;
        ChatBus.$emit('get-pricing');

        return null;
      }

      return this.wrongPageResponse(
        "If you'd like to get pricing please go to the ",
        '/pe/loan-scenario',
        'Loan Scenario Page',
      );
    },
    explainPricing(isPricingRequest = false) {
      if (this.bestPrices) {
        this.addMessage({
          sender: this.botName,
          text: this.generatePricingSuccessResponse(
            this.bestPrices,
            isPricingRequest,
          ),
        });
      } else {
        this.addMessage({
          sender: this.botName,
          text: generateResponse(
            ["I don't see any pricing info"],
            ['Please re-run pricing and ask again'],
            apologies,
            null,
            0.9,
            '.',
            '.',
          ),
        });
      }
    },
    parseFieldErrors(errors) {
      const fields = errors
        .map(error => error.split(' ')[0])
        .map(error => error.split('.')[1]);
      const fieldDisplayNames = fields.map(field => {
        if (field.includes('countyFipsCode')) {
          field = field.replace('countyFipsCode', 'county');
        }
        field = camelCaseToTitleCase(field);

        return field;
      });

      const bulletList = fieldDisplayNames
        .map(field => `- ${field}`)
        .join('\n');

      return bulletList;
    },
    streamAudioFromServer(prompt) {
      if (!this.vocalResponseEnabled) {
        return;
      }
      // Stop currently playing audio
      if (this.currentlyPlayingAudio) {
        this.currentlyPlayingAudio.pause();
        this.currentlyPlayingAudio = null;
      }

      // Create an axios request to send the data
      axios
        .post(
          '/nli/tts',
          { prompt: prompt },
          {
            headers: { 'Content-Type': 'application/json' },
            responseType: 'arraybuffer',
          },
        )
        .then(response => {
          // Create a new Blob from the response data
          const audioBlob = new Blob([response.data], {
            type: 'audio/ogg; codecs=opus',
          });

          // Create URL from the Blob
          const audioUrl = URL.createObjectURL(audioBlob);

          // Create a new Audio object and set its source to the URL
          this.currentlyPlayingAudio = new Audio(audioUrl);

          // Play the audio
          this.currentlyPlayingAudio.play();
        })
        .catch(error => console.error('Error streaming audio', error));
    },
    generatePricingSuccessResponse(minPrices, isPricingRequest = false) {
      const minPricesList = Object.entries(minPrices).map(
        ([key, value]) =>
          `- ${key}: ${value.price.rate}% rate on the ${value.product.name} product`,
      );
      const bestPriceStatement =
        minPricesList.length > 1
          ? 'The lowest rate par prices...'
          : 'The lowest rate par price...';

      const followUp = minPricesList.length
        ? ['\n\n' + bestPriceStatement + ` \n\n${minPricesList.join('\n')}`]
        : followUps;

      const eligibleSentiments = isPricingRequest
        ? [...pricingRetrievalSentiments, ...genericTaskCompleteSentiments]
        : affirmatives;

      const sentiments = minPricesList.length
        ? eligibleSentiments
        : ['It looks like there are no eligible products'];

      const followUpEndChar = minPricesList.length ? '.' : '?';

      return generateResponse(
        sentiments,
        followUp,
        null,
        null,
        null,
        '.',
        followUpEndChar,
      );
    },
    parseSolutionRequirements(solutionRequirements) {
      return solutionRequirements.reduce((acc, curr, i) => {
        acc += `${i + 1}. ${curr.parameter} ${curr.operator} ${
          curr.requirement
        } `;
        acc += `(failed because ${curr.parameter} is currently ${curr.parameterValue})\n`;
        return acc;
      }, '');
    },
    parseNearMissContext(nearMissProducts) {
      let result = '';
      for (const product of nearMissProducts) {
        const product_name = `Product: ${product.name}\n`;
        result = result ? result + `\n\n${product_name}` : product_name;
        const solution = product?.solutions[0];
        for (const parameter in solution) {
          const value = solution[parameter];
          result += ` - ${parameter} must be ${value}\n`;
        }
      }
      return result;
    },
    async addMessage(message) {
      if (message?.sender === this.botName) {
        this.currentMessage.response = message.text;
        const response = await NLIService.addMessage(this.currentMessage);
        message.id = response.message_id;
        message.reaction = 0;
      }
      this.allMessages.push(message);
      this.newMessageHandler(message);
    },
    async newMessageHandler(newMessage) {
      const messageRecord = {
        content: newMessage.text,
        role: 'user',
        sentAt: new Date(),
      };
      if (newMessage.sender === this.botName) {
        this.isSending = false;
        this.streamAudioFromServer(newMessage.text);
        messageRecord.role = 'assistant';
      }

      // Only summarize messages of sufficient length, otherwise the
      // summarization is counter productive in that it actually adds tokens instead of reducing
      if (messageRecord.content.length > 100) {
        const response = await this.executeNliAction(
          'summarization',
          async options =>
            NLIService.summarize(
              {
                prompt: messageRecord.content,
                history: null,
              },
              options,
            ),
        );
        messageRecord.content = response.data.result;
        if (newMessage?.id) {
          // Check if the message has an id, if so, it means the message has been saved to the database and we can add the action. Otherwise, the action will have been stored in the current message and will be added when the message is saved.
          NLIService.addMessageAction(newMessage.id, {
            actions: [
              this.constructActionAnalyticsData('summarization', response),
            ],
          });
        }
      }
      this.addToHistory(messageRecord);
    },
    async executeNliAction(
      actionName,
      apiAction,
      options = {},
      subclassification = '',
    ) {
      options = {
        timeout: 60000,
        ...options,
      };

      const response = await apiAction(options).catch(error => {
        if (error.code === 'ECONNABORTED') {
          this.addMessage({
            sender: this.botName,
            text: 'Sorry, something went wrong, please try again.',
          });
        }
      });

      const action = this.constructActionAnalyticsData(
        actionName,
        response,
        subclassification,
      );

      const training_example = this.constructTrainingExample(
        response,
        actionName,
      );
      action.training_example = training_example;
      response.training_example = training_example;

      this.addActionToCurrentMessage(action);

      return response;
    },
    constructTrainingExample(response, actionName) {
      if (actionName === 'json-translation') {
        return this.formatTrainingExample(
          response,
          'translation_system_message',
          this.jsonHistoryLength,
        );
      } else if (actionName === 'traffic-cop') {
        return this.formatTrainingExample(
          response,
          'traffic_cop_system_message',
          this.trafficCopHistoryLength,
        );
      } else if (actionName === 'mortgage-info') {
        return this.formatTrainingExample(
          response,
          'mortgage_info_system_message',
          this.historyLength,
          response?.data?.system_message,
        );
      } else if (actionName === 'contextualizer') {
        return this.formatTrainingExample(
          response,
          'CONTEXT_FILL_SYSTEM_MESSAGE',
          this.historyLength,
        );
      } else if (actionName === 'explain-ineligibility') {
        return this.formatTrainingExample(
          response,
          'INELIGIBILITY_EXPLANATION_SYSTEM_MESSAGE',
          this.ineligibilityHistoryLength,
        );
      } else if (actionName === 'rate-stack-search') {
        return this.formatTrainingExample(
          response,
          'semantic_match_system_message',
          0,
        );
      } else if (actionName === 'normalize-and-tag') {
        return this.formatTrainingExample(response, 'system_message', 0);
      }

      return '';
    },
    constructActionAnalyticsData(actionName, response, subclassification = '') {
      return {
        name: actionName,
        result: response.data.result,
        subclassification: subclassification,
        ai_model: response.data.usage.model,
        model_provider: response.data.usage.model_provider,
        characters: response.data.usage.characters,
        audio_seconds: response.data.usage.audio_seconds,
        prompt_tokens: response.data.usage.prompt_tokens,
        completion_tokens: response.data.usage.completion_tokens,
        total_tokens: response.data.usage.total_tokens,
        cost: response.data.usage.cost,
        history: response.data?.history || '',
      };
    },
    addActionToCurrentMessage(action) {
      if (!this.currentMessage.actions) {
        this.currentMessage.actions = [];
      }
      this.currentMessage.actions.push(action);
    },
    formatTrainingExample(
      response,
      systemMessageVariableString,
      historyLength = 2,
      system_message = null,
    ) {
      let systemContent = '';

      if (system_message) {
        systemContent = system_message;
      } else {
        let conversationHistory = '';
        if (historyLength > 0) {
          conversationHistory = `+ """\\n\\nConversation History: \\n ${this.historyToString(
            historyLength,
          )}"""`;
        }

        systemContent = systemMessageVariableString + ' ';
        if (response?.data?.additional_context) {
          systemContent +=
            '+ """\\n\\n' + response?.data?.additional_context + '"""';
        }
        if (response?.data?.translation_schema) {
          systemContent +=
            `+ '\\n\\n'` +
            JSON.stringify(JSON.stringify(response?.data?.translation_schema));
        }
        systemContent += conversationHistory;
      }

      const prompt = response?.data?.templated_prompt || response?.data?.prompt;

      const formatted = `{
          "messages": [
            {
              "role": 'system',
              "content": ${systemContent},
            },
            {
              "role": 'user',
              "content": "${prompt.replace(/"/g, '\\"')}",
            },
            {
              "role": 'assistant',
              "content": "${response.data.result.replace(/"/g, '\\"')}",
            },
          ],
        },\n`;

      return formatted;
    },
    ...mapActions({
      mergeScenarioWithTemplate: 'pricing/mergeScenarioWithTemplate',
    }),
  },
};
