<template>
  <div>
    <component
      :is="getReactionComponent()"
      :message="message"
      @thumbDown="thumbDown"
      @thumbUp="thumbUp"
    />
  </div>
</template>

<script>
import ThumbUp from './ThumbUp.vue';
import ThumbDown from './ThumbDown.vue';
import ThumbSelector from './ThumbSelector.vue';
import * as NLIService from '@shared/services/nli.js';

export default {
  components: {
    ThumbUp,
    ThumbDown,
    ThumbSelector,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    handleReactionSelection: {
      type: Function,
      required: true,
    },
  },
  methods: {
    getReactionComponent() {
      if (this.message.reaction === 1) {
        return 'ThumbUp';
      } else if (this.message.reaction === 2) {
        return 'ThumbDown';
      } else if (this.message.reaction === 0) {
        return 'ThumbSelector';
      }
    },
    async submitReaction(reaction) {
      const message = this.message;
      if (message) {
        const response = await NLIService.updateMessage(message.id, {
          reaction: reaction,
        });
        const reponseReaction = response?.updated_message?.reaction || 0;
        this.handleReactionSelection(message.id, reponseReaction);
      }
    },
    async thumbUp() {
      this.submitReaction(1);
    },
    async thumbDown() {
      this.submitReaction(2);
    },
  },
};
</script>
