<template>
  <span v-if="isEnabled" data-testid="side-nav-container-mobile">
    <a
      class="mobile-navbar navbar-hamburger navbar-hamburger-button"
      @click.prevent="toggleSideNav"
    >
      <px-icon icon="bars" size="lg" />
    </a>
    <nav :class="navBarClasses">
      <div class="navbar-container">
        <div class="logo-row">
          <router-link class="polly-logo nav-button" exact-path to="/">
            <img
              alt="Polly logo"
              class="big-screen-logo"
              src="@shared/assets/images/polly-logo-black.svg"
            />
            <img
              alt="Polly logo"
              class="medium-screen-logo"
              src="@shared/assets/images/polly-mark-rgb-black.svg"
            />
            <a
              class="navbar-hamburger-button mobile-hamburger"
              @click.prevent="toggleSideNav"
            >
              <px-icon icon="bars" size="lg" />
            </a>
          </router-link>
        </div>
        <side-nav-org-switcher />
        <div class="separation-line"></div>
        <div class="flex-nav-items">
          <!-- PE -->
          <side-nav-link-group
            v-if="orgUserPermissions.can_access_pe"
            large-text="PRICING ENGINE"
            text="PE"
            :to="{ name: 'loan-scenarios' }"
          >
            <side-nav-link
              v-if="orgUserPermissions.can_access_loan_pipeline"
              icon="eye"
              navitem="pe_test_scenarios"
              text="Loan Scenarios"
              :to="{ name: 'loan-scenarios' }"
            />
          </side-nav-link-group>

          <!-- Tools -->
          <side-nav-link-group
            v-if="orgUserPermissions.can_access_lock_updater"
            text="TOOLS"
            :to="{ name: 'toolsHome' }"
          >
            <side-nav-link
              icon="pen-to-square"
              text="Lock Updater"
              :to="{ name: 'lock_updater' }"
            />
          </side-nav-link-group>
          <!-- Dev tools -->
          <side-nav-link-group
            v-if="orgUserPermissions.can_access_seed_data"
            text="DEV"
            :to="{ name: 'devtoolsHome' }"
          >
            <side-nav-link
              icon="face-glasses"
              text="Seed Test Data"
              :to="{ name: 'seed_data_scenario' }"
            />
          </side-nav-link-group>
        </div>
        <div class="separation-line"></div>
        <span
          @click="navigateTo('/accounts/password/change/')"
          v-if="!orgUserPermissions.is_sso_enabled"
        >
          <side-nav-link
            icon="shield-keyhole"
            text="Change Password"
            to="/accounts/password/change/"
          />
        </span>
        <span @click="navigateTo('/accounts/logout/')">
          <side-nav-link
            class="nested"
            icon="arrow-right-from-bracket"
            text="Sign Out"
            to="/accounts/logout/"
          ></side-nav-link>
        </span>
      </div>
      <button @click.exact="toggleSideNav">
        <div :class="shadowClasses"></div>
      </button>
    </nav>
  </span>
</template>

<script>
import { mapGetters } from 'vuex';

import SideNavOrgSwitcher from './SideNavOrgSwitcher.vue';
import SideNavLinkGroup from './SideNavLinkGroup.vue';
import SideNavLink from './SideNavLink.vue';

const ESCAPE_CODE = 'Escape';

export default {
  components: { SideNavLink, SideNavLinkGroup, SideNavOrgSwitcher },
  props: {},
  data: function () {
    return {
      isVisible: false,
      isEnabled: true,
    };
  },
  computed: {
    ...mapGetters({
      orgUserPermissions: 'core/orgUserPermissions',
      orgPermissions: 'core/orgPermissions',
      userInfo: 'core/userInfo',
    }),
    hideSideNav() {
      return this.$route.params?.hideSideNav ?? false;
    },
    profileHeaderText() {
      return this.userInfo?.full_name ?? 'Profile';
    },
    navBarClasses() {
      return {
        'polly-navbar': true,
        'nav-slide-in': this.isVisible,
        'nav-slide-out': !this.isVisible,
      };
    },
    shadowClasses() {
      return {
        'navbar-shadow': true,
        'shadow-slide-in': this.isVisible,
        'shadow-slide-out': !this.isVisible,
      };
    },
    showNotifications() {
      return this.orgUserPermissions?.can_access_notifications;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        const queryShowSideNav = to.query?.showSideNav;
        if (typeof queryShowSideNav !== 'undefined') {
          let queryVisibility = queryShowSideNav;
          if (typeof queryShowSideNav === 'string') {
            queryVisibility = queryShowSideNav === 'true';
          }
          this.isEnabled = queryVisibility;
        } else {
          this.isEnabled = to.meta?.showSideNav ?? true;
        }
      },
    },
  },
  mounted() {
    document.addEventListener('keyup', this.handleEsc);
  },
  destroyed() {
    document.removeEventListener('keyup', this.handleEsc);
  },
  methods: {
    toggleSideNav() {
      this.isVisible = !this.isVisible;
    },
    handleEsc($event) {
      if (this.isVisible && $event.code === ESCAPE_CODE) this.isVisible = false;
    },
    navigateTo(path) {
      location.href = path;
    },
  },
};
</script>

<style lang="scss">
@import '@shared/styles/variables';

.mobile-hamburger {
  display: block;
  width: 100%;
  text-align: right;
  padding-right: 21px;

  > svg {
    width: 23px;
  }
}

.mobile-navbar {
  .nested {
    .nav-button .nav-icon {
      font-size: 14px;

      .px-icon {
        overflow: visible;
        height: 14px;
        width: 14px;
      }

      &:before {
        content: '\2022';
        margin-right: 8px;
      }
    }
  }

  .navbar-shadow {
    top: 0;
    left: 194px;
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .polly-navbar {
    background: #ffffff;
    border: 1px solid #f2f2f2;
    bottom: 0;
    box-shadow:
      inset 190px 0 5px 0 white,
      inset 0 0 6px 1px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    z-index: 100;

    & > nav {
      height: 100%;
    }

    & > .navbar-container {
      width: 194px;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 0 10px 21px;
      overflow-y: auto;
      overflow-x: hidden;

      & > .flex-nav-items {
        flex: 1;
      }
    }

    .router-link-active {
      border-right: 2px solid #4591e4;
    }

    .router-link-active.nav-button > .nav-icon,
    .router-link-active.nav-button > .nav-text {
      color: #4591e4;
      font-weight: 600;
    }

    .router-link-active.nav-button > .nav-icon svg.px-icon {
      color: #4591e4;
    }
  }

  .logo-row {
    margin: 18px 0 0 0;
  }

  .polly-nav-items {
    margin-bottom: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0 0 0 0;

    .nested {
      display: flex;

      .bullet {
        height: 4px;
        margin: auto 0;
        margin-right: 8px;
        width: 4px;
      }
    }
  }

  .polly-logo {
    width: 100%;

    & > img {
      height: 14px;
    }
  }

  .nav-button-padding {
    margin: 0 0 0 0;
    padding: 8px 0 8px 0;
  }

  .medium-screen-text {
    display: none;
  }

  .parent-item > .sub-items {
    display: none;
  }

  .flex-nav-items > .polly-nav-items > .parent-item:only-child > .sub-items,
  .active-parent-item > .sub-items {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    list-style-type: none;
    padding: 0 0 0 0;
  }

  .benchmark-padding {
    padding-bottom: 30px;
  }

  .separation-line {
    border: 1px solid #eeeeee;
    margin: 10px 20px 10px 0;
  }

  .main {
    > .mai-wrapper {
      margin-left: 0;
    }
  }

  .polly-navbar {
    left: -196px;

    ~ .mai-wrapper {
      margin-left: 0;
    }
  }

  .navbar-shadow {
    display: block !important;
  }

  .navbar-hamburger {
    z-index: 100;
    position: fixed;
    top: 20px;
    left: 24px;
    height: 43px;
    background-color: #f4f4f4;
    border-radius: 21px;
    justify-content: center;
    box-shadow: $polly-default-shadow;
    display: block;
    width: 100%;
    text-align: right;
    padding-right: 21px;

    & > svg {
      display: flex;
      align-self: center;
      width: 23px;
    }
  }
  .nav-slide-in {
    display: block !important;
    animation: 0.3s nav-slide-in;
    left: 0;
  }

  .shadow-slide-in {
    animation: 0.3s shadow-slide-in;
    left: 194px;
  }

  .nav-slide-out {
    animation: 0.3s alternate nav-slide-out;
    left: -196px;
  }

  .shadow-slide-out {
    display: none !important;
  }

  @keyframes nav-slide-in {
    from {
      left: -196px;
    }

    to {
      left: 0;
    }
  }

  @keyframes shadow-slide-in {
    from {
      left: 0;
    }

    to {
      left: 194px;
    }
  }

  @keyframes nav-slide-out {
    from {
      left: 0;
    }

    to {
      left: -196px;
    }
  }

  /*
User Orgs Accordion section
*/

  .user-orgs-accordion {
    font-size: 0.8rem;

    span,
    .btn .icon {
      font-size: 0.8rem;
    }

    .btn-container {
      text-align: left;
    }

    .default-org-btn {
      padding-left: 0;

      &.no-user-orgs {
        cursor: auto;

        .icon.fa-chevron-down,
        .icon.fa-chevron-up {
          display: none;
        }
      }

      &.collapsed .icon.fa-chevron-up,
      &:not(.collapsed) .icon.fa-chevron-down {
        display: none;
      }
    }

    .ticker-link {
      display: none;
    }

    ul {
      padding-left: 20px;
      margin-bottom: 0;
    }

    .more-user-orgs-link {
      padding-left: 5px;
    }

    .flip {
      display: inline-block;
      vertical-align: bottom;
      animation: flip 0.3s linear;
    }

    #userOrgsCollapse li {
      padding: 2px 0;
    }
  }

  @keyframes flip {
    from {
      transform: scaleY(-1);
    }
    to {
      transform: scaleY(1);
    }
  }
}
</style>
