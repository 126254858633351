import Vue from 'vue';
import { ApmVuePlugin } from '@elastic/apm-rum-vue';

import App from './App.vue';
import router from './router.js';
import store from './store';
import { logInit } from '@shared/utils/logging.js';
import installGa from '@shared/utils/ga.js';
import interceptorsSetup from '@shared/services/interceptors.js';
import Shared from '@shared/index';

import 'element-ui/lib/theme-chalk/index.css';
import 'tippy.js/themes/light-border.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import('@pe/styles/pe.scss');
import('@sell/styles/sell.scss');
import('@useradmin/styles/useradmin.scss');

installGa(router);
logInit('lxapp', Vue);
Vue.use(ApmVuePlugin, {
  router,
  config: {
    serviceName: 'lxapp-frontend',
    serverUrl: window.APM_SERVER_URL,
    environment: window.ENVIRONMENT,
    breakdownMetrics: true,
  },
});

// Register shared components
Vue.use(Shared);

Vue.config.devtools = process.env.NODE_ENV === 'development';
Vue.config.debug = process.env.NODE_ENV === 'development';
Vue.config.productionTip = process.env.NODE_ENV === 'development';

interceptorsSetup();

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app');
