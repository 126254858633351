import {
  CUSTOM_PARAM_CATEGORY,
  CUSTOM_PARAMETER_TYPES_CONFIG,
  PERuleCategories,
  PERuleFieldDisplayNameMapping,
  PERuleFieldPropertyUnitString,
  PERuleFields,
  PERuleFieldsCountyFipsCode,
  PERuleFieldsChannel,
  PERuleFieldsCustomValue,
  PERuleFieldUnitsKey,
  PERuleGroupBaseRate,
  PERuleGroupBorrower,
  PERuleGroupLoan,
  PERuleGroupProduct,
  PERuleGroupProperty,
  PERuleGroupRequest,
  EntityTypes,
  CUSTOM_PARAMETER_TYPES,
  STATE_FIPS,
} from '@shared/constants';
import {
  getRule,
  getAllCounties,
  getEntities,
  getAudiences,
} from '@pe/services/configurations.js';
import { onErrorHandler } from '@shared/utils/errorHandlers.js';
import {
  parseBooleanStructure,
  validResultStructure,
} from '@pe/components/Configure/RuleDetails/RulesLogic.js';
import clone from 'lodash/cloneDeep';
import api from '@shared/services/api.js';

import store from '@src/store';

const CATEGORY_TO_FIELD_GROUP = {
  [CUSTOM_PARAM_CATEGORY.CUSTOM]: PERuleGroupBaseRate,
  [CUSTOM_PARAM_CATEGORY.BORROWER]: PERuleGroupBorrower,
  [CUSTOM_PARAM_CATEGORY.LOAN]: PERuleGroupLoan,
  [CUSTOM_PARAM_CATEGORY.PROPERTY]: PERuleGroupProperty,
  [CUSTOM_PARAM_CATEGORY.PRODUCT]: PERuleGroupProduct,
};

export class RuleFields {
  constructor(ruleFields, params) {
    this.ruleFields = ruleFields;
    this.params = params || [];
    this.counties = [];
    this.valueGroups = [];
  }

  get fields() {
    return this.ruleFields;
  }

  getCustomParam(fieldName) {
    return (
      this.params.find(param => param.name === fieldName) ?? {
        valueCategory: CUSTOM_PARAM_CATEGORY.CUSTOM,
        valueType: CUSTOM_PARAMETER_TYPES.STRING,
      }
    );
  }

  getCustomField(fieldName) {
    const param = this.getCustomParam(fieldName);
    return this.ruleFields[CATEGORY_TO_FIELD_GROUP[param.valueCategory]][
      fieldName
    ];
  }

  get(group, field) {
    if (group === PERuleFieldsCustomValue && field) {
      return this.getCustomField(field);
    } else if (field && field !== 'null') {
      return this.ruleFields[group][field];
    } else {
      return this.ruleFields[group];
    }
  }

  getFullDisplayName(group, field) {
    if (group === PERuleFieldsCustomValue && field) {
      const param = this.getCustomParam(field);
      group = CATEGORY_TO_FIELD_GROUP[param.valueCategory];
    }

    const displayGroupName = this.getGroupDisplayName(group);
    if (!group && !field) {
      return null;
    } else if (!field || field === 'null') {
      return displayGroupName;
    } else {
      const displayFieldName = this.getFieldDisplayName(group, field);
      return `${displayGroupName} ${displayFieldName}`;
    }
  }

  getGroupDisplayName(group) {
    return PERuleFieldDisplayNameMapping[group]
      ? PERuleFieldDisplayNameMapping[group]
      : group;
  }

  getFieldDisplayName(group, field) {
    let displayFieldName = field;
    if (PERuleFieldDisplayNameMapping[`${group}.${field}`]) {
      displayFieldName = PERuleFieldDisplayNameMapping[`${group}.${field}`];
    }
    return displayFieldName;
  }

  parseField(field) {
    /**
     * Parses rule configuration structure fields from pe3 to js group and field
     */
    if (!field) {
      return {
        entityName: null,
        propertyName: null,
      };
    }

    if (/^Product.((C|HC)?LTV|MaxOfLTVandCLTV(andHCLTV)?)$/i.test(field)) {
      return {
        entityName: PERuleGroupLoan,
        propertyName: field.replaceAll(/^.*(\("|\.)|"\)/g, ''),
      };
    } else if (field.startsWith(PERuleFieldsCustomValue)) {
      return {
        entityName: PERuleFieldsCustomValue,
        propertyName: field.replaceAll(/^.*(\("|\.)|"\)/g, ''),
      };
    } else if (field.startsWith(PERuleFieldsChannel)) {
      return {
        entityName: PERuleGroupBaseRate,
        propertyName: PERuleFieldsChannel,
      };
    } else if (
      Object.keys(this.ruleFields[PERuleGroupRequest]).includes(field)
    ) {
      return {
        entityName: PERuleGroupRequest,
        propertyName: field,
      };
    } else {
      const [entityName, propertyName] = field.split('.');
      return {
        entityName: entityName,
        propertyName: propertyName,
      };
    }
  }

  getFieldName(group, field) {
    /**
     * Generates pe3 rule configuration structure field from js group and field
     */
    if (this.params.some(param => param.name === field)) {
      return `${PERuleFieldsCustomValue}("${field}")`;
    }

    const defaultResult = `${group}.${field}`;
    switch (group) {
      case PERuleGroupLoan:
        switch (field.toUpperCase()) {
          case 'LTV':
            return `${PERuleGroupProduct}.${field}`;
          case 'CLTV':
            return `${PERuleGroupProduct}.${field}`;
          case 'HCLTV':
            return `${PERuleGroupProduct}.${field}`;
          case 'MAXOFLTVANDCLTV':
            return `${PERuleGroupProduct}.${field}`;
          case 'MAXOFLTVANDCLTVANDHCLTV':
            return `${PERuleGroupProduct}.${field}`;
          default:
            return defaultResult;
        }
      case PERuleGroupBaseRate:
        switch (field) {
          case PERuleFieldsChannel:
            return PERuleFieldsChannel;
          default:
            return defaultResult;
        }
      case PERuleGroupProperty:
        if (field === PERuleFieldPropertyUnitString) {
          return `${group}.${PERuleFieldUnitsKey}`;
        } else {
          return defaultResult;
        }
      case PERuleGroupRequest:
        return field;
      default:
        return defaultResult;
    }
  }
}

export const isClampCategory = category => category === PERuleCategories.Clamp;

export async function constructRuleStatement(configId, ruleId, category) {
  try {
    const ruleFields = await getPERuleFields(configId);
    const ruleObject = await getRule(configId, ruleId);
    const clauses = await parseBooleanStructure(
      ruleObject.booleanEquationStructure,
      ruleFields,
    );
    if (ruleObject.resultEquationStructure) {
      validResultStructure(category, ruleObject.resultEquationStructure);
    }
    return { ruleFields, ruleObject, clauses };
  } catch (e) {
    onErrorHandler(e, 'construct-rule-statement', [403, 404]);
  }
}

export async function getPERuleFields(
  configurationId,
  params = null,
  audiences = null,
) {
  const disableFetchEntities = !!store?.state?.core?.isMiRateCard;

  if (!params) {
    params = disableFetchEntities
      ? []
      : await getEntities(configurationId, 'custom-parameters');
  }
  if (!audiences) {
    audiences = disableFetchEntities ? [] : await getAudiences(configurationId);
  }

  const ruleFields = {
    ...clone(PERuleFields),
  };
  params.forEach(value => {
    ruleFields[CATEGORY_TO_FIELD_GROUP[value.valueCategory]][value.name] = value
      .allowedValues?.length
      ? valueToObject(value)
      : CUSTOM_PARAMETER_TYPES_CONFIG[value.valueType].type;
  });

  const audiencesResult = audiences.map(audience => {
    return {
      id: audience.id,
      text: audience.name,
    };
  });

  const fields = {
    ...ruleFields,
    [PERuleGroupBaseRate]: {
      ...ruleFields[PERuleGroupBaseRate],
      [PERuleFieldsChannel]: audiencesResult,
    },
  };
  const ruleFieldsObj = new RuleFields(fields, params);

  const [counties, valueGroups] = await Promise.all([
    getAllCounties(),
    configurationId && !disableFetchEntities
      ? getEntities(configurationId, EntityTypes.ValueGroup + 's')
      : Promise.resolve([]),
  ]);

  addCountyFipsCodeValues(ruleFieldsObj, counties);

  ruleFieldsObj.counties = counties;
  ruleFieldsObj.valueGroups = valueGroups;

  return ruleFieldsObj;
}

function addCountyFipsCodeValues(ruleFieldsObj, counties) {
  // adding possible values to the property.countyFips parameter
  ruleFieldsObj.fields[PERuleGroupProperty][PERuleFieldsCountyFipsCode] =
    counties
      .map(state =>
        state.counties.map(
          county => STATE_FIPS[state.state_code] + county.county_fips,
        ),
      )
      .flat()
      .sort()
      .map(countyFips => ({
        id: countyFips,
        text: countyFips,
      }));
}

export function valueToObject(value) {
  return value.allowedValues.map(allowedValue => {
    return {
      id: allowedValue,
      text: allowedValue,
    };
  });
}

export function convertLLPAforUI(minStructure, maxStructure) {
  /*https://pollyex.atlassian.net/browse/OC-212
    In all cases where Total LLPA is concerned - it should be creating a rule that says “MIN LLPA >= {UI value * -1}”
    which will give you your desired effect in every case that I can think of.
    If the user enters a negative number, I think you’d want to just MAX instead of MIN and flip the value.
    For example….
    User enters Max LLPA of 0 the rule that is sent to the PE should be “MIN LLPA 0”
    User enters Max LLPA of 3 the rule that is sent to PE should be “MIN LLPA -3”
    User enters Max LLPA of -1.5 the rule that is sent to the PE should be “MAX LLPA 1.5”*/
  if (minStructure[0]?.value) {
    maxStructure.splice(0, maxStructure.length);
    maxStructure.push(...minStructure);
    maxStructure[0].value = +maxStructure[0].value * -1;
    maxStructure[0].content = +maxStructure[0].content * -1;
    minStructure.splice(0, minStructure.length);
  } else if (maxStructure[0]?.value && +maxStructure[0].value > 0) {
    maxStructure[0].value = +maxStructure[0].value * -1;
    maxStructure[0].content = +maxStructure[0].content * -1;
  }
}

export function convertLLPAforSave(minStructure, maxStructure) {
  const notValid = [null, undefined, '', []];
  if (
    !notValid.includes(maxStructure[0]?.value) &&
    +maxStructure[0].value >= 0
  ) {
    minStructure.splice(0, minStructure.length);
    minStructure.push(...maxStructure);
    minStructure[0].value = +minStructure[0].value * -1;
    minStructure[0].content = +minStructure[0].content * -1;
    maxStructure.splice(0, maxStructure.length);
  } else if (
    !notValid.includes(maxStructure[0]?.value) &&
    +maxStructure[0].value < 0
  ) {
    maxStructure[0].value = +maxStructure[0].value * -1;
    maxStructure[0].content = +maxStructure[0].content * -1;
  }
}

export async function uploadGrid(file, gridHeaders, gridInfo) {
  const fileName = file.name;
  const formData = new FormData();
  formData.append('grid', file, fileName);
  formData.append(
    'gridHeaders',
    JSON.stringify({
      columnHeaders: gridHeaders.headerXNoName,
      rowHeaders: gridHeaders.headerYNoName,
    }),
  );
  formData.append('gridInfo', JSON.stringify(gridInfo));
  return await api.postFormData('/pe/api/grid-upload', formData);
}
