import 'vue-select/dist/vue-select.css';

// global vendor
import { Vue2Storage } from 'vue2-storage';
import BootstrapVue from 'bootstrap-vue';
import Datetime from 'vue-datetime';
import MaskedInput from 'vue-masked-input';
import VCalendar from 'v-calendar';
import VMoney from 'v-money';
import vSelect from 'vue-select';
import VueTheMask from 'vue-the-mask';
import { Timeline, TimelineItem } from 'element-ui';
import Vuelidate from 'vuelidate';
import 'vuelidate/lib/validators';
import frag from 'vue-frag';
import VueTippy, { TippyComponent } from 'vue-tippy';
import VueCumulioDashboard from '@cumul.io/vue-cumulio-dashboard/vue2';

//font awesome icons
import icons from '@shared/components/fontAwesome.js';

// custom filter
import filters from '@shared/filters.js';

//Px Custom Components
import PxBadge from '@shared/components/PxBadge.vue';
import PxBaseModal from '@shared/components/PxBaseModal.vue';
import PxBasePage from '@shared/components/PxBasePage.vue';
import PxBoxInput from '@shared/components/PxBoxInput.vue';
import PxBoxInputToggle from '@shared/components/PxBoxInputToggle.vue';
import PxButton from '@shared/components/PxButton.vue';
import PxCalendar from '@shared/components/PxCalendar.vue';
import PxCard from '@shared/components/PxCard.vue';
import PxCardTable from '@shared/components/PxCardTable.vue';
import PxCardTableFilter from '@shared/components/PxCardTableFilter.vue';
import PxCheckbox from '@shared/components/PxCheckbox.vue';
import PxConfirmModal from '@shared/components/PxConfirmModal.vue';
import PxDateRangePicker from '@shared/components/PxDateRangePicker.vue';
import PxDateTimePickerButton from '@shared/components/PxDateTimePickerButton.vue';
import PxDropdownMultiSelect from '@shared/components/PxDropdownMultiSelect.vue';
import PxErrorBoundary from '@shared/components/PxErrorBoundary.vue';
import PxFileUpload from '@shared/components/PxFileUpload.vue';
import PxFormGroup from '@shared/components/PxFormGroup.vue';
import PxIcon from '@shared/components/PxIcon.vue';
import PxIndicatorIconItem from '@shared/components/PxIndicatorIconItem.vue';
import PxInput from '@shared/components/PxInput.vue';
import PxLoadingWrapper from '@shared/components/PxLoadingWrapper.vue';
import PxMultiFileUpload from '@shared/components/PxMultiFileUpload.vue';
import PxMultiStepForm from '@shared/components/PxMultiStepForm.vue';
import PxPartnersMultiSelect from '@shared/components/PxPartnersMultiSelect.vue';
import PxRadio from '@shared/components/PxRadio.vue';
import PxRadioGroup from '@shared/components/PxRadioGroup.vue';
import PxRelatedObjectsPane from '@shared/components/PxRelatedObjectsPane.vue';
import PxSearch from '@shared/components/PxSearch.vue';
import PxSearchBox from '@shared/components/PxSearchBox.vue';
import PxSelect from '@shared/components/PxSelect.vue';
import PxSelect2 from '@shared/components/PxSelect2.vue';
import PxSplitButton from '@shared/components/PxSplitButton.vue';
import PxStatusIndicator from '@shared/components/PxStatusIndicator.vue';
import PxTable from '@shared/components/PxTable.vue';
import PxTabs from '@shared/components/PxTabs.vue';
import PxTextArea from '@shared/components/PxTextArea.vue';
import PxToasts from '@shared/components/Toast';
import PxTreeViewMultiselect from '@shared/components/PxTreeViewMultiselect.vue';

// TODO Reconcile these two dropdowns
import AppDropdown from '@shared/components/AppDropdown.vue';
import PxDropdown from '@shared/components/PxDropdown.vue';

export default {
  install: function (Vue) {
    if (Vue._shared_components_installed) {
      return;
    }
    Vue._shared_components_installed = true;

    // Global SCSS Styles
    import('@shared/styles/shared.scss');

    // Vendor Plugins
    Vue.use(icons);
    Vue.use(Vuelidate);
    Vue.use(Timeline);
    Vue.use(TimelineItem);
    Vue.use(BootstrapVue);
    Vue.use(Datetime);
    Vue.use(VCalendar);
    Vue.use(VMoney);
    Vue.use(VueTheMask);
    Vue.use(VueCumulioDashboard);
    Vue.use(Vue2Storage, {
      prefix: 'pollyex_app',
      driver: 'local',
    });
    Vue.use(VueTippy);
    Vue.use(PxToasts);

    // Directives
    Vue.directive('frag', frag);

    // Vendor
    Vue.component('MaskedInput', MaskedInput);
    Vue.component('VueTippy', TippyComponent);
    Vue.component('VSelect', vSelect);

    // TODO Reconcile these components
    Vue.component('LxDropdown', AppDropdown);
    Vue.component('PxDropdown', PxDropdown);

    // Px components
    Vue.component(PxBadge.name, PxBadge);
    Vue.component(PxBaseModal.name, PxBaseModal);
    Vue.component(PxBasePage.name, PxBasePage);
    Vue.component(PxBoxInput.name, PxBoxInput);
    Vue.component(PxBoxInputToggle.name, PxBoxInputToggle);
    Vue.component(PxButton.name, PxButton);
    Vue.component(PxCalendar.name, PxCalendar);
    Vue.component(PxCard.name, PxCard);
    Vue.component(PxCardTable.name, PxCardTable);
    Vue.component(PxCardTableFilter.name, PxCardTableFilter);
    Vue.component(PxCheckbox.name, PxCheckbox);
    Vue.component(PxConfirmModal.name, PxConfirmModal);
    Vue.component(PxDateRangePicker.name, PxDateRangePicker);
    Vue.component(PxDateTimePickerButton.name, PxDateTimePickerButton);
    Vue.component(PxDropdownMultiSelect.name, PxDropdownMultiSelect);
    Vue.component(PxErrorBoundary.name, PxErrorBoundary);
    Vue.component(PxFileUpload.name, PxFileUpload);
    Vue.component(PxFormGroup.name, PxFormGroup);
    Vue.component(PxIcon.name, PxIcon);
    Vue.component(PxIndicatorIconItem.name, PxIndicatorIconItem);
    Vue.component(PxInput.name, PxInput);
    Vue.component(PxLoadingWrapper.name, PxLoadingWrapper);
    Vue.component(PxMultiFileUpload.name, PxMultiFileUpload);
    Vue.component(PxPartnersMultiSelect.name, PxPartnersMultiSelect);
    Vue.component(PxMultiStepForm.name, PxMultiStepForm);
    Vue.component(PxRadio.name, PxRadio);
    Vue.component(PxRadioGroup.name, PxRadioGroup);
    Vue.component(PxRelatedObjectsPane.name, PxRelatedObjectsPane);
    Vue.component(PxSearch.name, PxSearch);
    Vue.component(PxSearchBox.name, PxSearchBox);
    Vue.component(PxSelect.name, PxSelect);
    Vue.component(PxSelect2.name, PxSelect2);
    Vue.component(PxSplitButton.name, PxSplitButton);
    Vue.component(PxStatusIndicator.name, PxStatusIndicator);
    Vue.component(PxTabs.name, PxTabs);
    Vue.component(PxTable.name, PxTable);
    Vue.component(PxTextArea.name, PxTextArea);
    Vue.component(PxTreeViewMultiselect.name, PxTreeViewMultiselect);

    filters.init(Vue);
  },
};
