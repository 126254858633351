<template>
  <span data-testid="side-nav-container">
    <a
      class="navbar-hamburger navbar-hamburger-button"
      @click.prevent="toggleSideNav"
    >
      <px-icon icon="bars" size="lg" />
    </a>
    <nav :class="navBarClasses">
      <div class="navbar-container">
        <div class="logo-row">
          <router-link class="polly-logo nav-button" exact-path to="/">
            <img
              alt="Polly logo"
              class="big-screen-logo"
              src="@shared/assets/images/polly-logo-black.svg"
            />
            <img
              alt="Polly logo"
              class="medium-screen-logo"
              src="@shared/assets/images/polly-mark-rgb-black.svg"
            />
            <a
              class="sm-screen-hamburger navbar-hamburger-button"
              @click.prevent="toggleSideNav"
            >
              <px-icon icon="bars" size="lg" />
            </a>
          </router-link>
        </div>
        <side-nav-org-switcher />
        <div class="separation-line"></div>
        <div class="flex-nav-items">
          <!-- PE -->
          <side-nav-link-group
            v-if="orgUserPermissions.can_access_pe"
            large-text="PRICING ENGINE"
            text="PE"
            :to="{ name: 'pricingHome' }"
          >
            <side-nav-link
              v-if="orgUserPermissions.can_access_publish_pricing"
              icon="rectangle-list"
              navitem="pe_versions"
              text="Publish Pricing"
              :to="{ name: 'pricing' }"
            />
            <side-nav-link
              v-if="
                orgUserPermissions.can_access_mi_rate_card &&
                isUserLockDeskOrHigher
              "
              icon="umbrella"
              navitem="mi_versions"
              text="Publish MI"
              :to="{ name: 'mortgage-insurance-rate-card' }"
            />
            <side-nav-link
              v-if="orgUserPermissions.can_access_lock_desk"
              icon="lock"
              navitem="pe_lock_desk"
              text="Lock Desk"
              :to="{ name: 'lock-desk' }"
            />
            <side-nav-link
              v-if="orgUserPermissions.can_access_loan_pipeline"
              :badge-text="actionRequiredCount"
              icon="clipboard"
              navitem="pe_loan_pipeline"
              text="Loan Pipeline"
              :to="{ name: 'loans' }"
            >
              <template v-if="actionRequiredCount > 99" #badge-content>
                99
                <sup>+</sup>
              </template>
            </side-nav-link>
            <side-nav-link
              v-if="orgUserPermissions.can_access_loan_pipeline"
              icon="eye"
              navitem="pe_test_scenarios"
              text="Loan Scenarios"
              :to="{ name: 'loan-scenarios' }"
            />
          </side-nav-link-group>
          <!-- Sell -->
          <side-nav-link-group
            v-if="
              orgUserPermissions.can_access_exchange && orgPermissions.is_seller
            "
            text="SELL"
            :to="{ name: 'sellHome' }"
          >
            <side-nav-link
              icon="list-check"
              text="Loan Manager"
              :to="{ name: 'loanManager' }"
            />
            <side-nav-link
              icon="upload"
              text="Uploads"
              :to="{ name: 'uploads' }"
            />
            <side-nav-link
              icon="clipboard"
              text="Pipeline"
              :to="{ name: 'pipeline' }"
            />
            <side-nav-link
              icon="cart-shopping"
              text="Cart"
              :to="{ name: 'commitmentCart' }"
            />
            <side-nav-link
              icon="clipboard-check"
              text="Committed Loans"
              :to="{ name: 'committedLoans' }"
            />
            <side-nav-link
              icon="box"
              text="Committed Pools"
              :to="{ name: 'committedPools' }"
            />
          </side-nav-link-group>
          <!-- Buy -->
          <side-nav-link-group
            v-if="
              orgUserPermissions.can_access_exchange && orgPermissions.is_buyer
            "
            text="BUY"
            :to="{ name: 'buyHome' }"
          >
            <side-nav-link
              icon="download"
              text="Bid Tape"
              :to="{ name: 'bidTape' }"
            />
            <side-nav-link
              icon="magnifying-glass"
              text="Loan Discovery"
              :to="{ name: 'discovery' }"
            />
            <side-nav-link
              icon="upload"
              text="Upload Bids"
              :to="{ name: 'bidUpload' }"
            />
            <side-nav-link
              icon="hourglass-half"
              text="Active Bids"
              :to="{ name: 'activeBids' }"
            />
            <side-nav-link
              icon="clipboard-check"
              text="Committed Loans"
              :to="{ name: 'buyCommittedLoans' }"
            />
          </side-nav-link-group>
          <!-- Deliver -->
          <side-nav-link-group
            v-if="orgUserPermissions.can_access_delivery"
            text="DELIVER"
            :to="{ name: 'deliverHome' }"
          >
            <side-nav-link
              icon="box"
              navitem="deliver_ready"
              text="Ready"
              :to="{ name: 'ready' }"
            />
            <side-nav-link
              icon="paper-plane"
              navitem="deliver_in_progress"
              text="In Progress"
              :to="{ name: 'in-progress' }"
            />
            <side-nav-link
              icon="circle-check"
              navitem="deliver_delivered"
              text="Delivered"
              :to="{ name: 'delivered' }"
              tooltip="Delivered"
            ></side-nav-link>
          </side-nav-link-group>
          <!-- Analytics -->
          <side-nav-link-group
            v-if="orgUserPermissions.can_access_analytics"
            text="ANALYTICS"
            :to="{ name: 'analyticsHome' }"
          >
            <side-nav-link
              icon="display-chart-up"
              text="Analytics"
              :to="{ name: 'analyticsHome' }"
            />
          </side-nav-link-group>
          <!-- Tools -->
          <side-nav-link-group
            v-if="orgUserPermissions.can_access_lock_updater"
            text="TOOLS"
            :to="{ name: 'toolsHome' }"
          >
            <side-nav-link
              icon="pen-to-square"
              text="Lock Updater"
              :to="{ name: 'lock_updater' }"
            />
          </side-nav-link-group>
          <!-- Dev tools -->
          <side-nav-link-group
            v-if="orgUserPermissions.can_access_seed_data"
            text="DEV"
            :to="{ name: 'devtoolsHome' }"
          >
            <side-nav-link
              icon="face-glasses"
              text="Seed Test Data"
              :to="{ name: 'seed_data_scenario' }"
            />
          </side-nav-link-group>
        </div>
        <div class="separation-line"></div>
        <side-nav-link
          v-if="showNotifications"
          icon="bell"
          text="Notifications"
          :to="{ name: 'notification-preferences' }"
        ></side-nav-link>
        <side-nav-link-group
          :emphasize-text="false"
          icon="circle-user"
          show-tooltip
          :text="profileHeaderText"
          :to="{ name: 'account_profile' }"
        >
          <side-nav-link
            class="nested"
            icon="users-viewfinder"
            text="Membership"
            :to="{ name: 'account_membership' }"
          />
          <span
            @click="navigateTo('/accounts/password/change/')"
            v-if="!orgUserPermissions.is_sso_enabled"
          >
            <side-nav-link
              class="nested"
              icon="shield-keyhole"
              text="Change Password"
              to="/accounts/password/change/"
            />
          </span>
          <span @click="navigateTo('/accounts/logout/')">
            <side-nav-link
              class="nested"
              icon="arrow-right-from-bracket"
              text="Sign Out"
              to="/accounts/logout/"
            ></side-nav-link>
          </span>
        </side-nav-link-group>
        <side-nav-link
          v-if="orgUserPermissions.is_admin"
          :exact-path-match="true"
          icon="cog"
          text="Admin"
          :to="{ name: 'useradmin' }"
        />
      </div>
      <button :class="shadowClasses" @click.exact="toggleSideNav"></button>
    </nav>
  </span>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { pollWhenActive } from '@shared/utils/polling.js';
import SideNavOrgSwitcher from './SideNavOrgSwitcher.vue';
import SideNavLinkGroup from './SideNavLinkGroup.vue';
import SideNavLink from './SideNavLink.vue';

const ESCAPE_CODE = 'Escape';

export default {
  components: { SideNavLink, SideNavLinkGroup, SideNavOrgSwitcher },
  props: {},
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      orgUserPermissions: 'core/orgUserPermissions',
      orgPermissions: 'core/orgPermissions',
      userInfo: 'core/userInfo',
      actionRequiredCount: 'loanPipeline/actionRequiredCount',
      isUserLockDeskOrHigher: 'core/isUserLockDeskOrHigher',
    }),
    hideSideNav() {
      return this.$route.params?.hideSideNav ?? false;
    },
    profileHeaderText() {
      return this.userInfo?.full_name ?? 'Profile';
    },
    navBarClasses() {
      return {
        'polly-navbar': true,
        'nav-slide-in': this.isVisible,
        'nav-slide-out': !this.isVisible,
      };
    },
    shadowClasses() {
      return {
        'navbar-shadow': true,
        'shadow-slide-in': this.isVisible,
        'shadow-slide-out': !this.isVisible,
      };
    },
    showNotifications() {
      return this.orgUserPermissions?.can_access_notifications;
    },
    hasAutoTriggeredRepriceEnabled() {
      return this.orgPermissions?.has_auto_triggered_reprice_enabled;
    },
  },

  mounted() {
    document.addEventListener('keyup', this.handleEsc);

    if (this.showNotifications) {
      pollWhenActive(() => this.fetchUnreadCount(), 60000, 0.1);
    }

    if (this.hasAutoTriggeredRepriceEnabled) {
      pollWhenActive(() => this.fetchActionRequiredCountPolling(), 30000, 0.1);
    }
  },
  destroyed() {
    document.removeEventListener('keyup', this.handleEsc);
  },
  methods: {
    ...mapActions({
      fetchUnreadCount: 'notifications/fetchUnreadCount',
      fetchActionRequiredCountPolling:
        'loanPipeline/fetchActionRequiredCountPolling',
    }),
    toggleSideNav() {
      this.isVisible = !this.isVisible;
    },
    handleEsc($event) {
      if (this.isVisible && $event.code === ESCAPE_CODE) this.isVisible = false;
    },
    navigateTo(path) {
      location.href = path;
    },
  },
};
</script>

<style lang="scss">
@import '@shared/styles/variables';

$active-color: $polly-light-blue;
$inactive-color: $polly-dark-blue;

.main {
  > .mai-wrapper {
    margin-left: 194px;
  }
}

.nav-button {
  display: flex;
  align-items: center;
  padding: 8px 0 8px 0;

  &:hover {
    .nav-icon {
      color: $active-color;
    }

    .nav-text {
      color: $active-color;
    }
  }

  &.nested {
    padding: 4px 0 4px 0;
  }

  & .nav-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    width: 20px;
    height: 20px;
    margin-right: 16px;
    color: $inactive-color;
    position: relative;
    transition: color 100ms ease-in-out;

    .icon__badge {
      position: absolute;
      font-size: 0.7rem;
      left: 10px;
      top: -6px;

      .super-text {
        margin-left: -2px;
      }
    }
  }

  & > .nav-icon svg.px-icon {
    width: 20px;
    height: 20px;
  }

  & > .nav-text {
    font-size: 12px;
    line-height: 15px;
    color: $inactive-color;
    white-space: normal;
    transition: color 100ms ease-in-out;
  }

  & > .nav-text-header {
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
  }
}

.nested {
  .nav-button .nav-icon {
    font-size: 14px;

    .px-icon {
      overflow: visible;
      height: 14px;
      width: 14px;
    }

    &:before {
      content: '\2022';
      margin-right: 8px;
    }
  }
}

.navbar-shadow {
  top: 0;
  left: 194px;
  height: 100vh;
  width: 100%;
  position: fixed;
  appearance: none;
  border: none;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
}

.polly-navbar {
  background: #ffffff;
  border: 1px solid #f2f2f2;
  bottom: 0;
  box-shadow:
    inset 190px 0 5px 0 white,
    inset 0 0 6px 1px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 100;

  .polly-logo:focus-visible {
    outline: 2px solid $polly-light-blue;
    outline-offset: 2px;
    border-radius: 5px;
  }

  a.nav-button {
    &:focus-visible {
      text-decoration: none;

      .nav-text,
      .nav-icon {
        color: $active-color;
      }

      .nav-text {
        text-decoration: underline;
      }
    }
  }

  & > nav {
    height: 100%;
  }

  & > .navbar-container {
    width: 194px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    overflow-y: auto;
    overflow-x: hidden;

    & > .flex-nav-items {
      flex: 1;
    }
  }

  .router-link-active {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: -1rem;
      border-right: 2px solid $active-color;
    }
  }

  .router-link-active.nav-button > .nav-icon,
  .router-link-active.nav-button > .nav-text {
    color: $active-color;
    font-weight: 600;
  }

  .router-link-active.nav-button > .nav-icon svg.px-icon {
    color: $active-color;
  }
}

.medium-screen-logo {
  display: none;
}

.sm-screen-hamburger {
  display: none;

  & > svg {
    width: 23px;
  }
}

.polly-nav-items {
  margin-bottom: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0 0 0 0;

  .nested {
    display: flex;

    .bullet {
      height: 4px;
      margin: auto 0;
      margin-right: 8px;
      width: 4px;
    }
  }
}

.polly-logo {
  width: 100%;

  & > img {
    height: 14px;
  }
}

.nav-button-padding {
  margin: 0 0 0 0;
  padding: 8px 0 8px 0;
}

.medium-screen-text {
  display: none;
}

.parent-item > .sub-items {
  display: none;
}

.flex-nav-items > .polly-nav-items > .parent-item:only-child > .sub-items,
.active-parent-item > .sub-items,
.parent-item:focus-visible > .sub-items,
.parent-item:focus-within:not(:active) > .sub-items {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  list-style-type: none;
}

.benchmark-padding {
  padding-bottom: 30px;
}

.separation-line {
  border: 1px solid #eeeeee;
  margin: 10px 20px 10px 0;
}

.navbar-hamburger {
  display: none;
}

@media (min-width: 1199px) {
  .polly-navbar {
    display: block !important;
  }

  .navbar-shadow {
    display: none !important;
  }
}

@media (max-width: 1399px) and (min-width: 776px) {
  .main {
    > .mai-wrapper {
      margin-left: 82px;
    }
  }

  .polly-navbar {
    display: block !important;
    box-shadow:
      inset 58px 0 5px 0 white,
      inset 0 0 6px 1px rgba(0, 0, 0, 0.25);

    & > .navbar-container {
      width: 82px;
      padding-left: 0;
    }

    & ~ .mai-wrapper {
      margin-left: 82px;
    }
  }

  .polly-nav-items .nested {
    padding-left: 24px;
  }

  .navbar-shadow {
    display: none !important;
  }

  .logo-row {
    width: 100%;
    justify-content: center;
  }

  .polly-logo,
  .sub-items {
    margin-left: 0 !important;
  }

  .polly-logo,
  .nav-button {
    display: flex;
    justify-content: center;
  }

  .nav-button > .nav-icon + .nav-text {
    display: none !important;
  }

  .nav-icon {
    margin-right: 0 !important;
  }

  .big-screen-text {
    display: none;
  }

  .medium-screen-text {
    display: block;
  }

  .big-screen-logo {
    display: none;
  }

  .user-orgs-accordion {
    span.ticker-link {
      display: inline-block;
    }

    .full-link {
      display: none;
    }

    div.btn-container,
    #userOrgsCollapse div {
      text-align: center;
    }

    .btn-container .default-org-btn {
      padding: 10px 5px;
    }

    #userOrgsCollapse ul {
      padding-left: 0;
      text-align: center;
    }

    li {
      list-style-type: none;
    }

    div a.more-user-orgs-link {
      padding-left: 0;
    }
  }

  .medium-screen-logo {
    display: block;
    width: 32px !important;
    height: 32px !important;
  }

  .separation-line {
    margin: 10px 10px 10px 10px;
  }

  .nav-icon.nested {
    width: 14px !important;
  }
}

@media (max-width: 776px) {
  .main {
    > .mai-wrapper {
      margin-left: 0;
    }
  }

  .polly-navbar {
    left: -196px;

    ~ .mai-wrapper {
      margin-left: 0;
    }
  }

  .navbar-shadow {
    display: block !important;
  }

  .navbar-hamburger {
    display: flex;
    z-index: 100;
    position: fixed;
    top: 20px;
    left: 24px;
    width: 43px;
    height: 43px;
    background-color: #f4f4f4;
    border-radius: 21px;
    justify-content: center;
    box-shadow: $polly-default-shadow;

    & > svg {
      display: flex;
      align-self: center;
      width: 23px;
    }
  }

  .sm-screen-hamburger {
    display: block;
    width: 100%;
    text-align: right;
    padding-right: 21px;
  }

  .nav-slide-in {
    display: block !important;
    animation: 0.3s nav-slide-in;
    left: 0;
  }

  .shadow-slide-in {
    animation: 0.3s shadow-slide-in;
    left: 194px;
  }

  .nav-slide-out {
    animation: 0.3s alternate nav-slide-out;
    left: -196px;
  }

  .shadow-slide-out {
    display: none !important;
  }

  @keyframes nav-slide-in {
    from {
      left: -196px;
    }

    to {
      left: 0;
    }
  }

  @keyframes shadow-slide-in {
    from {
      left: 0;
    }

    to {
      left: 194px;
    }
  }

  @keyframes nav-slide-out {
    from {
      left: 0;
    }

    to {
      left: -196px;
    }
  }
}

/*
User Orgs Accordion section
*/

.user-orgs-accordion {
  font-size: 0.8rem;

  span {
    font-size: 0.8rem;
  }

  .btn .icon {
    font-size: 1rem;
  }

  .btn-container {
    text-align: left;
  }

  .default-org-btn {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    margin: 0;

    &.no-user-orgs {
      cursor: auto;
    }
  }

  .ticker-link {
    display: none;
  }

  ul {
    padding-left: 20px;
    margin-bottom: 0;
  }

  .more-user-orgs-link {
    padding-left: 5px;
  }

  #userOrgsCollapse li {
    padding: 2px 0;
  }
}
</style>
