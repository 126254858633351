import api from '@shared/services/api.js';
const baseApi = '/nli';

export const trafficCop = async (body, options = {}) => {
  return api.post(`${baseApi}/traffic-cop`, body, options);
};

export const translateJson = async (body, options = {}) => {
  return api.post(`${baseApi}/translation`, body, options);
};

export const translateBooleanJson = async (body, options = {}) => {
  return api.post(`${baseApi}/translation-boolean`, body, options);
};

export const contextualize = async (body, options = {}) => {
  return api.post(`${baseApi}/contextualizer`, body, options);
};

export const normalizeAndTag = async (body, options = {}) => {
  return api.post(`${baseApi}/normalization-tagger`, body, options);
};

export const selectColumns = async (body, options = {}) => {
  return api.post(`${baseApi}/column-selector`, body, options);
};

export const explainRates = async (body, options = {}) => {
  return api.post(`${baseApi}/rate-explainer`, body, options);
};

export const semanticMatch = async (body, options = {}) => {
  return api.post(`${baseApi}/semantic-match`, body, options);
};

export const mortgageInfo = async (body, options = {}) => {
  return api.post(`${baseApi}/mortgage-info`, body, options);
};

export const optionSelection = async (body, options = {}) => {
  return api.post(`${baseApi}/option-selection`, body, options);
};

export const nameExtraction = async (body, options = {}) => {
  return api.post(`${baseApi}/name-extraction`, body, options);
};

export const scenarioAnalysis = async (body, options = {}) => {
  return api.post(`/pe/api/scenario-analysis/`, body, options);
};

export const nearMiss = async (body, options = {}) => {
  return api.post(`/pe/api/near-miss/`, body, options);
};

export const explainLoanIneligibility = async (body, options = {}) => {
  return api.post(`${baseApi}/explain-ineligibility`, body, options);
};

export const explainNearMiss = async (body, options = {}) => {
  return api.post(`${baseApi}/explain-near-miss`, body, options);
};

export const summarize = async (body, options = {}) => {
  return api.post(`${baseApi}/summarization`, body, options);
};

export const addMessage = async (body, options = {}) => {
  return api.post(`${baseApi}/messages`, body, options);
};

export const addMessageAction = async (messageId, body, options = {}) => {
  return api.patch(
    `${baseApi}/messages/${messageId}/add-action/`,
    body,
    options,
  );
};

export const transcribeAudio = async audioBase64 => {
  return api.post(
    '/nli/audio-transcription',
    {
      audio: {
        data: audioBase64,
      },
      model: 'whisper-1',
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export const updateMessage = async (messageId, body, options = {}) => {
  return api.patch(`/nli/messages/${messageId}/`, body, options);
};
