<template>
  <ul class="chat-widget__messages">
    <li
      v-for="(message, index) in allMessages"
      :key="index"
      :class="{
        'chat-widget__message--user': message.user,
        'chat-widget__message--other': !message.user,
      }"
    >
      <chat-message
        :handle-reaction-selection="handleReactionSelection"
        :message="message"
        :other-profile-image="otherProfileImage"
      />
    </li>
    <li v-if="isSending" class="chat-widget__message--other">
      <div class="chat-widget__message-sender">
        <div class="chat-message-box">
          <img
            alt="Profile Image"
            class="chat-profile-image"
            :src="otherProfileImage"
          />
          <span
            class="chat-widget__message-bubble chat-widget__message-bubble--other"
          >
            <typing-indicator />
          </span>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import TypingIndicator from './TypingIndicator.vue';
import ChatMessage from './ChatMessage.vue';

export default {
  name: 'ChatMessageList',
  components: {
    TypingIndicator,
    ChatMessage,
  },
  props: {
    allMessages: {
      type: Array,
      required: true,
    },
    isSending: {
      type: Boolean,
      required: true,
    },
    otherProfileImage: {
      type: String,
      required: true,
    },
    handleReactionSelection: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import './ChatWidget.scss';
.chat-widget__messages {
  /* take up available space and allow scrolling */
  flex-grow: 1;
  overflow-y: auto;
  list-style: none;
  margin: 0;
  padding: 10px;
}

.chat-widget__message--user {
  border-radius: 10px;
  padding: 10px;
  align-self: flex-end;
  background-color: $polly-light-blue;
  color: #fff;
  width: fit-content;
  margin-left: auto;
  margin-right: 0;
  max-width: 80%;
  margin-bottom: 10px;
}

.chat-widget__message--user:hover {
  background-color: $polly-light-blue;
}

.chat-widget__message--other {
  align-self: flex-start;
  margin-right: auto;
  margin-left: 0;
  width: fit-content;
  max-width: 90%;
  margin-bottom: 10px;
}

.chat-widget__message--other:hover {
  background-color: unset;
}
</style>
