import Pluralize from 'pluralize';

export const capitalize = string => {
  if (!string) return string;

  const lower = string.toLowerCase();

  return `${string[0].toUpperCase()}${lower.slice(1)}`;
};

export const camelCaseToTitleCase = camelCase => {
  if (camelCase === null || camelCase.includes(' ')) {
    return camelCase;
  }

  // Capitalize the first letter of each word
  const spaceSeparated = camelCase.replace(/([A-Z])/g, ' $1').toLowerCase();

  return spaceSeparated
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

// parts is an array of arrays where the first item of
// each array is the term used and the second is the count.
// Example:
//   [['year', 10], ['month', 5]]
export const joinParts = (parts, delimiter = ', ') =>
  parts
    .filter(([_term, count]) => !!count) // eslint-disable-line no-unused-vars
    .map(([term, count]) => `${count} ${Pluralize(term, count)}`)
    .join(delimiter);
