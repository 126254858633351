import {
  getAgencies,
  getServicingProviders,
  getPartners,
  getAgencyProducts,
  getUserDetails,
  getPermissions,
} from '@shared/services/coreApi.js';
import { getAgencies as getOrgAgencies } from '@shared/api/agencies.js';
import { getServicingProviders as getOrgServicingProviders } from '@shared/api/servicingProviders.js';
import couponRatesSvc from '@shared/services/couponRates.js';
import productPayupsSvc from '@shared/services/payUps.js';
import { servicingOptionsExtended, ROLE_TYPES } from '@shared/constants';
import { reloadWaffle } from '@shared/utils';
import { PERuleSubCategories } from '@shared/constants';

const coreActions = {
  async refreshWaffle({ commit }) {
    await reloadWaffle(() => commit('initFlagsAndSwitches'));
  },
  async getAgencies({ commit }) {
    const agencies = await getAgencies();
    commit('setAgencies', agencies);
  },
  async getOrgAgencies({ commit, rootGetters }) {
    const response = await getOrgAgencies(
      rootGetters['organizations/currentOrganizationId'],
    );
    commit('setOrgAgencies', response.results);
  },
  async getServicingProviders({ commit, getters }) {
    if (getters.servicingProviders?.length) {
      return;
    }
    const providers = await getServicingProviders();
    commit('setServicingProviders', providers);
  },
  async getOrgServicingProviders({ commit, getters, rootGetters }) {
    if (getters.orgServicingProviders?.length) {
      return;
    }
    const response = await getOrgServicingProviders(
      rootGetters['organizations/currentOrganizationId'],
    );
    commit('setOrgServicingProviders', response.results);
  },
  async getPartners({ commit }, { forSeller }) {
    const partners = await getPartners(forSeller);
    commit('setPartners', partners);
  },
  async getAgencyProducts({ commit }) {
    const agencyProducts = await getAgencyProducts();
    commit('setAgencyProducts', agencyProducts);
  },
  async getUserInfo({ commit }) {
    const userInfo = await getUserDetails();
    commit('setUserInfo', userInfo);
  },
  async getPermissions({ commit, state }) {
    let permissions;
    if (state.permissions) {
      permissions = state.permissions;
    } else {
      permissions = await getPermissions();
      commit('setPermissions', permissions);
      commit('setUserOrgs', permissions?.userOrgs);
    }
    return permissions;
  },
  async getCouponRates({ commit }) {
    const couponRates = await couponRatesSvc.getCouponRates();
    commit('setCouponRates', couponRates?.results);
  },
  async getPayupProducts({ commit, rootGetters }, tickerSymbol) {
    const payupProducts = await productPayupsSvc.getPayupProducts(
      rootGetters['organizations/currentOrganizationId'],
      tickerSymbol,
    );
    commit('setPayupProducts', payupProducts?.results);
  },
};

function servicingOptions(state) {
  const baseServicingOptions =
    state.servicingProviders?.reduce((res, provider) => {
      res[provider.name] = provider.ticker_symbol;
      return res;
    }, {}) || {};
  return { ...baseServicingOptions, ...servicingOptionsExtended };
}

export const coreGetters = {
  servicingOptions,
  agencies: state => state.agencies || [],
  orgAgencies: state => state.orgAgencies || [],
  servicingProviders: state => state.servicingProviders || [],
  orgServicingProviders: state => state.orgServicingProviders || [],
  partners: state => state.partners || [],
  agencyProducts: state => state.agencyProducts || {},
  userInfo: state => state.userInfo || {},
  orgUserPermissions: state => state.permissions?.orgUser || {},
  orgPermissions: state => state.permissions?.org || {},
  adjPermissions: (_, getters) => {
    const permissions = {};
    for (const key of Object.keys(PERuleSubCategories)) {
      if (key != PERuleSubCategories.None) {
        const permission = `can_view_${key.toLowerCase()}_margin`;
        permissions[key] = getters?.orgUserPermissions?.[permission];
      }
    }

    return permissions;
  },
  userOrgs: state => state.userOrgs || [],
  couponRates: state => state.couponRates || [],
  payupProducts: state => state.payupProducts || [],
  isLoanOfficer: state =>
    state.userInfo?.roles?.includes(ROLE_TYPES.PE_LOAN_OFFICER),
  isCapitalMarkets: state =>
    state.userInfo?.roles?.includes(ROLE_TYPES.PE_CAPITAL_MARKETS),
  isApprover: state => state.userInfo?.roles?.includes(ROLE_TYPES.PE_APPROVER),
  isFlagActive: state => flagName => !!state.flags[flagName],
  isSwitchActive: state => switchName => !!state.switches[switchName],
  isAdmin: (_, getters) => getters.orgUserPermissions?.is_admin ?? false,
  canAccessPriceUIOnly: state =>
    (state && state.userInfo && state.userInfo.can_access_price_ui_only) ||
    false,
  isUserLockDeskOrHigher: (_, getters) => {
    if (getters.isAdmin) return true;
    return getters.userInfo?.roles?.some(role_type =>
      [ROLE_TYPES.PE_CAPITAL_MARKETS, ROLE_TYPES.PE_LOCK_DESK].includes(
        role_type,
      ),
    );
  },
  userIgnoresPolicies: (_, getters) => {
    return (
      getters.isUserLockDeskOrHigher &&
      getters.orgPermissions.has_lock_desk_request_workflows_enabled
    );
  },
  canViewHiddenAdjustments: (_, getters) => {
    return getters.isUserLockDeskOrHigher && !getters.canAccessPriceUIOnly;
  },
};

export const coreMutations = {
  initFlagsAndSwitches(state) {
    state.flags = window.waffle.FLAGS;
    state.switches = window.waffle.SWITCHES;
  },
  setAgencies(state, agencies) {
    state.agencies = agencies;
  },
  setOrgAgencies(state, agencies) {
    state.orgAgencies = agencies;
  },
  setServicingProviders(state, servicingProviders) {
    state.servicingProviders = servicingProviders;
  },
  setOrgServicingProviders(state, servicingProviders) {
    state.orgServicingProviders = servicingProviders;
  },
  setPartners(state, partners) {
    state.partners = partners;
  },
  setAgencyProducts(state, agencyProducts) {
    state.agencyProducts = agencyProducts;
  },
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo;
  },
  setPermissions(state, permissions) {
    state.permissions = permissions;
  },
  setCouponRates(state, couponRates) {
    state.couponRates = couponRates;
  },
  setPayupProducts(state, payupProducts) {
    state.payupProducts = payupProducts;
  },
  setUserOrgs(state, userOrgs) {
    state.userOrgs = userOrgs;
  },
  setIsMiRateCard(state, isMiRateCard) {
    state.isMiRateCard = isMiRateCard;
  },
};

const coreState = {
  agencies: null,
  orgAgencies: [],
  servicingProviders: null,
  orgServicingProviders: null,
  partners: null,
  agencyProducts: null,
  userInfo: {},
  permissions: null,
  couponRates: null,
  payupProducts: null,
  flags: window.waffle?.FLAGS || {},
  switches: window.waffle?.SWITCHES || {},
  userOrgs: [],
  isMiRateCard: false,
};

export default {
  namespaced: true,
  state: coreState,
  getters: coreGetters,
  mutations: coreMutations,
  actions: coreActions,
};
