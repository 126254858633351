import { pickRandomArrayItem } from '@shared/utils/helpers';

export const generateResponse = (
  sentiments,
  followUps,
  prefixes = null,
  pleasantries = null,
  prefixPleasantryRate = 0.5,
  sentimentEndChar = '.',
  followUpEndChar = '?',
) => {
  let sentiment = pickRandomArrayItem(sentiments);
  let followUp = pickRandomArrayItem(followUps);

  if (pleasantries !== null && Math.random() < prefixPleasantryRate) {
    const pleasantry = pickRandomArrayItem(pleasantries);
    followUp = `${pleasantry} ${
      followUp.charAt(0) !== 'I' ? followUp.toLowerCase() : followUp
    }`;
  }

  if (prefixes !== null && Math.random() < prefixPleasantryRate) {
    const prefix = pickRandomArrayItem(prefixes);
    sentiment = `${prefix}, ${
      sentiment.charAt(0) !== 'I' ? sentiment.toLowerCase() : sentiment
    }`;
  }

  return `${sentiment}${sentimentEndChar} ${followUp}${followUpEndChar}`;
};

export const loanTranslationSentiments = [
  "I've updated those values for you",
  'Your values have been updated',
  "I've made those changes",
  'Your changes are all set',
  'That change was made',
  'Update complete',
  'That scenario was updated',
  'Your changes are made',
  'Your new values are set',
  'Your scenario updated',
  "I'm done with those changes",
  'The values are up to date',
  'The values have been updated',
];

export const loadedScenarioSentiments = [
  "I've loaded that scenario for you",
  'Your scenario loaded',
  "Here's your scenario",
  "I've got the scenario you asked for",
  'Your scenario is ready',
  'Your scenario is ready to go',
  "I've loaded the scenario",
  'That scenario is now ready for you',
];

export const loadedScenarioFailureSentiments = [
  'I was unable to load that scenario for you',
  'There was a problem loading your scenario',
  'The specified scenario could not be readied',
  "I've failed to set up the scenario you requested",
  'Loading of your scenario was not completed',
  'There was an issue preparing your scenario',
  'The scenario you asked for could not be initialized',
  'The requested scenario failed to activate',
  'There was a problem loading that scenario',
  "I've failed to load the scenario",
  'That scenario could not be readied for you',
  'Initialization of your requested scenario failed',
  'Loading your scenario was unsuccessful',
  "The scenario you asked for didn't load",
  "Your scenario couldn't be prepared",
  'I was unable to activate that scenario for you',
  'I couldn’t load that scenario for you',
  'Seems like there was a hiccup loading your scenario',
  'That particular scenario slipped on a banana peel',
  'Regrettably, your requested scenario missed the bus',
  'Had a bit of mishap getting your scenario ready',
  'The scenario you asked for is playing hide-and-seek',
  'Had a slight mishap trying to find that scenario',
  'Seems like a gremlin sabotaged loading your requested scenario',
  'I stumbled loading that scenario',
  'The scenario you asked for did not materialize',
  'Perhaps your scenario is a bit shy and isn’t ready for the spotlight, or perhaps it’s just not there',
];

export const savedScenarioSentiments = [
  "I've saved that scenario",
  'Your scenario has been saved',
  "I've stored that scenario",
  'The requested scenario has been saved',
  'Scenario saved',
  "I've successfully saved the scenario",
  'Saved it',
  'Saved that scenario',
  'Your scenario is saved',
];

export const savedScenarioFailureSentiments = [
  'I was unable to save that scenario',
  'There was a problem saving that scenario',
  'The scenario could not be saved',
  "I couldn't save that scenario ",
  'There was an issue saving your scenario',
  'There was a problem saving the scenario',
  "I wasn't able to save that scenario",
];

export const ineligibleProductFailureSentiments = [
  "That ineligible product couldn't be found",
  "I was unable to find the ineligible product you're looking for",
  'There was a problem finding the ineligible product',
  'The specified ineligible product could not be found',
  "I've failed to find the ineligible product you requested",
  'There was an issue locating that ineligible product',
  'The ineligible product you asked for could not be found',
  'There was a problem finding that ineligible product',
  "I've failed to locate the ineligible product",
  'That ineligible product could not be found',
  "I couldn't find that ineligible product",
  'That ineligible product could not be located',
  'I was unable to find that ineligible product',
  "I couldn't find the ineligible product you're looking for",
];

export const pricingRetrievalSentiments = [
  "I've retrieved the pricing for you",
  "I've fetched the pricing",
  'Pricing ready',
  'I have the pricing information you asked for',
  'Your pricing info is here',
  'Your pricing information is ready',
  'Your pricing details are ready',
  'I have retrieved the pricing',
  'I have your pricing for you',
  'Pricing retrieval is successful',
  'The requested pricing is now available',
  'Your pricing information has been retrieved',
  'The pricing details are now available',
  'I have retrieved the pricing for you',
  'Got your pricing details',
];

export const genericTaskCompleteSentiments = [
  'Done',
  'Good to go',
  'All done',
  'Finished',
];

export const affirmatives = [
  'Sure',
  'Absolutely',
  'Of course',
  'Certainly',
  'Sure thing',
  'Roger that',
];

export const celebrations = [
  'Woohoo',
  'Ta-da',
  'Voila',
  'High five',
  'Nice',
  'Boom',
  'Score',
  'Huzzah',
  'Bingo',
];

export const followUps = [
  'Anything else I can do',
  'What can I help with next',
  'Do you need anything else',
  'Anything else',
  'What else can I do for you',
  'Anything else I can help with',
  'What else can I assist with',
  'Can I help with anything else',
  'What can I do next',
  'Got any other requests',
  'What else would you like me to do',
  'What more can I help with ',
  'How can I be of service',
];

export const apologies = [
  'Sorry',
  'That was unexpected',
  "Unfortunately that did't work",
  'Something went wrong there',
  "I'm sorry",
  'Apologies',
  'I made a mistake',
  'Oh no',
];

export const fieldIssuesList = [
  'These fields need attention',
  'Something is wrong with these fields',
  'There are issues with these fields',
  'There are some problems with the following fields',
  'These fields might have issues',
  'There are some issues with the following fields',
  'These fields need to be reviewed',
  'The following fields need updates',
  'These fields need a little fixing',
];

export const inabilityList = [
  "I'm not sure I can help with that",
  "I don't think I can assist with that",
  "That's beyond my ability",
  "That's not something I can help with",
  "I'm not able to help with that",
  "That's not within my abilities",
  "I'm not the best fit for that",
  'My comprehension of that topic is limited',
  'I am unable to assist with that',
  'I lack the skills to help with that',
  "I can't help with that",
  "I'm unable to do that",
  "That's not really my area of expertise",
  "I'm just not equipped to help with that one",
  'That might be out of my reach',
];

export const formUpdateFailureList = [
  'I was unable to find any values to update',
  "I couldn't find any values to update",
  'I was not able to find any values to update',
  'I was unsuccessful in finding values to update',
];

export const tryAgainList = [
  'Try another way',
  'Give it another shot',
  'Try a different prompt',
  'Try rephrasing that',
  'Try once more',
  'Try again',
  'Try a different phrasing',
  'Change the phrasing and try again',
  'Try some different wording',
  'Give it another go',
  'Give it another shot',
];

export const pleasantryList = [
  'If you could',
  'Please, could you',
  'Could you please',
  'I recommend you',
  'Please try to',
  'My suggestion would be to',
  'Please',
];
