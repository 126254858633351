<template>
  <div>
    <div v-if="!message.user" class="chat-widget__message-sender">
      <div class="chat-message-box">
        <img
          alt="Profile Image"
          class="chat-profile-image"
          :src="otherProfileImage"
        />
        <span
          class="chat-widget__message-bubble chat-widget__message-bubble--other"
        >
          <span v-text="message.text"></span>
          <router-link v-if="message.link" :to="message.link">
            <span v-text="message.linkText"></span>
          </router-link>
        </span>
        <message-reaction
          :handle-reaction-selection="handleReactionSelection"
          :message="message"
        />
      </div>
    </div>
    <span v-if="message.user" class="chat-widget__message-bubble">
      <span v-text="message.text"></span>
    </span>
  </div>
</template>

<script>
import MessageReaction from './MessageReaction.vue';

export default {
  name: 'ChatMessage',
  components: {
    MessageReaction,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    otherProfileImage: {
      type: String,
      required: true,
    },
    handleReactionSelection: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import './ChatWidget.scss';
</style>
