<template>
  <b-dropdown
    v-bind="$attrs"
    :class="['px-dropdown', $attrs.class]"
    :toggle-class="[
      'px-dropdown_toggle',
      $attrs['toggle-class'],
      {
        'px-dropdown_toggle--bare': !togglePill,
        'px-dropdown_toggle--pill': togglePill,
      },
    ]"
    variant="link"
    v-on="$listeners"
  >
    <template v-if="!togglePill" #button-content>
      <px-icon :icon="toggleIcon" size="2x"></px-icon>
    </template>

    <div v-if="items.length">
      <div v-for="item in items" :key="`${item.label}-${item.label}`">
        <component
          :is="`b-dropdown-${item.type || 'item'}`"
          v-if="item.isVisible"
          :disabled="item.isDisabled"
          :to="item.to || null"
          @click="item.callback && item.callback()"
        >
          <px-icon
            v-if="item.iconName"
            class="mr-2"
            :icon="item.iconName"
          ></px-icon>
          {{ item.label }}
        </component>
      </div>
    </div>

    <slot></slot>

    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot v-bind="scope" :name="slot" />
    </template>
  </b-dropdown>
</template>

<script>
export default {
  name: 'PxDropdown',
  props: {
    items: {
      type: Array,
      default: () => [],
      required: false,
    },
    togglePill: {
      type: Boolean,
      default: false,
      required: false,
    },
    toggleIcon: {
      type: String,
      default: 'ellipsis',
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/variables';

.px-dropdown {
  padding: 12px 0;

  ::v-deep .dropdown-header {
    font-size: 1rem;
  }

  ::v-deep .dropdown-toggle {
    color: $primary;
    line-height: 1rem;
    height: unset;
    min-width: unset;
    padding: unset;
  }

  ::v-deep .dropdown-toggle:hover,
  ::v-deep .dropdown-toggle:active {
    background-color: unset;
    color: inherit;
  }

  ::v-deep {
    .dropdown-item,
    .dropdown-header {
      padding-bottom: 4px;
      padding-top: 4px;
    }
  }

  ::v-deep .px-dropdown_toggle {
    padding: 0;

    &--bare {
      border-width: 0;
      padding: 0;
    }

    &--pill {
      border: solid 1px $primary;
      border-radius: 16px;
      padding: 8px 24px;
    }
  }
}
</style>
