<template>
  <div class="toast-container">
    <transition-group name="fade" tag="p" @leave="onLeave($event, true)">
      <div
        v-for="(group, key) in toastGroups"
        :key="`key-${key}`"
        :ref="key"
        class="toast-group"
        :class="{
          expanded: group.expanded,
          grouped: group.toasts.length > 1,
        }"
        :style="firstToastHeight(key, group)"
      >
        <div class="d-flex clear-all">
          <b-button
            class="barebones btn-short short-autosize-pill mr-2"
            variant="primary"
            @click="expandGroup(group)"
          >
            collapse
          </b-button>
          <b-button
            class="barebones btn-short short-autosize-pill"
            variant="primary"
            @click="removeGroup(key)"
          >
            clear all
          </b-button>
        </div>
        <transition-group
          :name="group.expanded ? 'toast' : 'fade'"
          tag="p"
          @leave="onLeave($event, group.expanded)"
        >
          <toast-body
            v-for="(toast, index) in group.toasts"
            :id="toast.id"
            :key="String(toast.id)"
            :ref="key + index"
            :count="group.toasts.length"
            :hide-close="toast.hideClose"
            :style="{ '--index': index + 1 }"
            :text="toast.text"
            :title="toast.title"
            :type="toast.type"
            @click="
              !group.expanded &&
                index === 0 &&
                group.toasts.length > 1 &&
                expandGroup(group)
            "
            @remove-toast="removeToast(key, toast.id)"
          ></toast-body>
        </transition-group>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { toastBus } from './utils.js';
import ToastBody from './ToastBody.vue';
export default {
  name: 'PxToast',
  components: {
    ToastBody,
  },
  data() {
    return {
      toastGroups: {},
      duration: {
        success: 10000,
        warn: 0,
        error: 0,
      },
    };
  },
  mounted() {
    toastBus.$on('add', this.addToast);
  },
  methods: {
    /**
     * @param {Object} options
     * @param {('success'|'warn'|'error')} options.type
     * @param {String} options.title Header of the toast
     * @param {String} options.text Body text of the toast
     * @param {Number} options.duration how long the toast stays on screen. 0 for indefinite.
     */
    async addToast(options) {
      if (options.clean) {
        this.cleanToasts();
      } else {
        const key = options.title + options.type;
        const group = this.toastGroups[key];

        if (!group) {
          this.$set(this.toastGroups, key, { toasts: [], expanded: false });
        }

        const index = this.toastGroups[key].toasts.length;

        this.$set(this.toastGroups[key].toasts, index, options);

        const duration = options.duration || this.duration[options.type];

        if (duration) {
          this.toastGroups[key].toasts[index].timerId = setTimeout(() => {
            this.removeToast(key, options.id);
          }, duration);
        }
      }

      //this will force the height to recalculate.
      await this.$nextTick();
      this.$forceUpdate();
    },
    //remove all toasts
    cleanToasts() {
      for (const key in this.toastGroups) {
        this.toastGroups[key].toasts = [];
      }
    },
    removeGroup(key) {
      this.toastGroups[key].toasts = [];
      this.$delete(this.toastGroups, key);
    },
    async removeToast(key, id) {
      if (
        !(this.toastGroups[key].toasts && this.toastGroups[key].toasts.length)
      )
        return;
      const index = this.toastGroups[key].toasts.findIndex(
        toast => toast.id === id,
      );

      //cancel the timer if we have one for this toast.not all toasts will have a timer, so make sure it exists first.
      if (this.toastGroups[key].toasts[index]?.timerId) {
        clearTimeout(this.toastGroups[key].toasts[index].timerId);
      }

      if (index > -1) this.toastGroups[key].toasts.splice(index, 1);

      if (!this.toastGroups[key].toasts.length) delete this.toastGroups[key];

      // //this will force the height to recalculate.
      await this.$nextTick();
      this.$forceUpdate();
    },
    //this is to handle collapsing the toast group to the correct height, and transition the expand.
    // when the group is collapsed shrink the group to the first toasts height.
    //the wrapper around the toast group always has the height of the whole uncollapsed group
    // which we can transition to
    firstToastHeight(key, group) {
      if (!(this.$refs[key + 0] && this.$refs[key + 0][0])) return 0;
      return group.expanded
        ? {
            maxHeight:
              this.$refs[key][0].querySelector('p').clientHeight + 60 + 'px',
          }
        : {
            height:
              this.$refs[key + 0][0].$el.clientHeight +
              Math.min((group.toasts && group.toasts.length) * 15, 45) +
              'px',
          };
    },
    async expandGroup(group) {
      group.expanded = !group.expanded;
      await this.$nextTick();
      this.$forceUpdate();
    },
    onLeave(el, expanded = false) {
      if (!expanded) return;
      //we need to set specific heights so toasts transition correctly when leaving
      const height = getComputedStyle(el).height;
      el.style.height = height;
      el.style.transition = 'all .25s ease-in-out';

      //we set the timeout so the toast will be hidden before the height is transitioned to 0;
      setTimeout(() => {
        el.style.height = 0;
        el.style.padding = 0;
        el.style.margin = 0;
        el.style.opacity = 0;
      }, 250);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/variables';

.toast-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9999;
  margin: 1rem;
  pointer-events: none;
  max-width: 90vw;
  width: 400px;

  > p {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  .toast-group {
    transition: all 0.5s ease;
    position: relative;

    &:has(> p:empty) {
      margin: 0;
    }
    > p {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
    }

    .clear-all {
      transition:
        all 0.5s ease-in-out,
        z-index 0s;
      opacity: 0;
      position: sticky;
      top: 0;
      margin-bottom: -30px;
      pointer-events: none;
      justify-content: flex-end;
      z-index: -100;
      button {
        pointer-events: auto;
        box-shadow:
          0 4px 6px -1px rgb(0 0 0 / 0.1),
          0 2px 4px -2px rgb(0 0 0 / 0.1);
      }
    }

    .toast {
      // we only display the first and second sub toast when the group is collapsed
      z-index: calc(var(--index) * -1);
      transition: all 0.25s;

      &:nth-child(1) {
        top: 0px;
        z-index: 1000;
      }

      &:nth-child(2) {
        transform: scale(calc(1 - 1 / 25));
        top: 15px;
      }
      &:nth-child(3) {
        transform: scale(calc(1 - 2 / 25));
        top: 30px;
      }
      &:nth-child(n + 4) {
        transform: scale(calc(1 - var(--index) / 25));
        top: calc(var(--index) * 15px);
        opacity: 0;
      }
    }

    &.expanded {
      margin-top: 30px;
      margin-bottom: 30px;
      .toast {
        opacity: 1;
        position: relative;
        top: 0;
        transform: scale(1);
      }

      .clear-all {
        transition:
          all 0.5s ease-in-out,
          z-index 0.25s linear 0.5s;
        z-index: 9999;
        opacity: 1;
        margin-bottom: 0;
        margin-top: -30px;
      }

      .toast {
        transition: none;
      }
    }

    &.toast-move {
      transition: none;
      .toast {
        transition: none;
      }
    }
  }
}

//fade transition is when a toast group is collapse, the stacked toasts will just fade in
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter-active {
  &.toast {
    transition: all 0.5s ease-in-out;
  }
  transition: all 0.5s ease-in-out;
}

//transition when a un-grouped toast appears
.toast-enter-active {
  animation: 0.5s slideIn;
  animation-timing-function: ease-out;
}

//transition when a un-grouped toast leaves
.toast-leave-active,
.fade-leave-active {
  position: absolute;
  animation: 0.5s slideOut forwards;
  animation-timing-function: ease-in;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translatex(30px);
  }
  50% {
    opacity: 0;
    transform: translatex(30px);
  }
  100% {
    opacity: 1;
    transform: translatex(0);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: translatex(30px);
  }
  100% {
    opacity: 0;
    transform: translatex(30px);
  }
}
</style>
