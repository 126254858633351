<template>
  <div class="chat-widget__toggle">
    <span v-if="isExpanded" class="chat-widget__toggle-icon">-</span>
    <img
      v-if="!isExpanded"
      alt="Profile Image"
      class="chat-widget__toggle-icon"
      :src="toggleIcon"
    />
  </div>
</template>

<script>
export default {
  name: 'ChatWidgetToggle',
  props: {
    isExpanded: {
      type: Boolean,
      required: true,
    },
    toggleIcon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import '@shared/styles/variables.scss';

.chat-widget__toggle {
  width: 60px;
  height: 60px;
  box-shadow: 0 0 5px 0 $polly-light-blue;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.15s ease-in-out;
}

.chat-widget__toggle:hover {
  box-shadow: 0 0 10px 0 $polly-light-blue;
}

.chat-widget__toggle-icon {
  color: $polly-light-blue;
  font-size: 20px;
  user-select: none;
  width: 60px;
  text-align: center;
}
</style>
