<template>
  <div class="d-flex flex-row align-items-center" data-testid="plus-and-search">
    <px-search-box
      v-if="showSearch && canShowSearch"
      :class="{ 'mr-3': showPlus }"
      :small="true"
      @expanded="searchExpanded = $event"
      @search-text="search"
    ></px-search-box>
    <div class="form-override">
      <px-plus-item
        v-if="showPlus"
        :options="items"
        :placeholder="plusPlaceholder"
        @hidden="plusHidden($event)"
        @valueChosen="$emit('plusChosen', $event)"
      />
    </div>
  </div>
</template>

<script>
const PREFIX_LEN = 5;
import PxPlusItem from '@shared/components/PxPlusItem.vue';

export default {
  name: 'PlusAndSearch',
  components: {
    PxPlusItem,
  },
  props: {
    items: { type: Array, required: false, default: () => [] },
    plusPlaceholder: { type: String, required: false, default: 'Add Item' },
    showSearch: { type: Boolean, required: false, default: true },
    showPlus: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      plusExpanded: false,
      searchExpanded: false,
    };
  },
  computed: {
    canShowSearch() {
      return !this.plusExpanded;
    },
    searchPlusExpand() {
      // we need the function to watch both variables (searchExpanded, plusExpanded)
      return this.searchExpanded || this.plusExpanded;
    },
  },
  watch: {
    searchPlusExpand(value) {
      this.$emit('expanded', value);
    },
  },
  methods: {
    plusHidden(hidden) {
      this.plusExpanded = !hidden;
      if (!hidden) this.$emit('search', '');
    },
    search(text) {
      if (!text || text.length < PREFIX_LEN) return;
      const search = text.slice(PREFIX_LEN).trim().toLowerCase();

      this.$emit('search', search);
    },
  },
};
</script>
